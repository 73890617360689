/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Button, Col, Form, Input, message, Row, Space } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { userState } from '../store/atom';
import { postRequest } from '../utils/ajax';

const { Item } = Form;

const LoginForm: React.FC = () => {
  const [user, setUser] = useRecoilState(userState);
  const [disable, setDisable] = useState(false);
  const history = useHistory();
  const { formatMessage } = useIntl();

  const onFinish = async (values: any) => {
    setDisable(true);
    const result = await postRequest('/api/login/', values);

    if (!result.success) {
      if (result.error) {
        message.error(formatMessage({ id: `error.${result.error}` }));
      } else message.error('Unknown Error.');
      setDisable(false);
    } else {
      setUser({ ...result });
      history.push('/');
      message.success(`${formatMessage({ id: 'loginPage.welcome' })}, ${result.nickname}!`);
    }
  };

  if (user.userid) return <div />;

  return (
    <Row justify="center">
      <Col xs={24} sm={18}>
        <Form
          labelCol={{
            xs: { span: 24 },
            sm: { span: 8 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 16 },
          }}
          onFinish={onFinish}
        >
          <Item
            label="ID"
            name="userid"
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'loginPage.required.id' }),
              },
            ]}
          >
            <Input placeholder="ID" autoComplete="userid" />
          </Item>
          <Item
            label="PW"
            name="userpw"
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'loginPage.required.pw' }),
              },
            ]}
          >
            <Input placeholder="Password" type="password" />
          </Item>
          <Item labelCol={{ xs: 0 }} wrapperCol={{ xs: 24 }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Button block type="primary" htmlType="submit" disabled={disable}>
                {formatMessage({ id: 'loginPage.submitButton' })}
              </Button>
              <Button block>
                <Link to="/signup/lr2">
                  {formatMessage({ id: 'loginPage.signupButton' })} (LR2)
                </Link>
              </Button>
              <Button block>
                <Link to="/signup/beatoraja">
                  {formatMessage({ id: 'loginPage.signupButton' })} (Beatoraja with MinIR)
                </Link>
              </Button>
            </Space>
          </Item>
        </Form>
      </Col>
    </Row>
  );
};

export default LoginForm;
