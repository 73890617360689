import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Affix, Collapse, Menu, Table, message } from 'antd';
import qs from 'querystring';
import { useIntl } from 'react-intl';
import checkTable from '../utils/checkTable';
import levelArray from '../utils/levelArray';
import irUrl from '../utils/irUrl';
import { SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import RecommendProps from '../props/recommend';
import { postRequest } from '../utils/ajax';

const { Panel } = Collapse;
const { Item } = Menu;
const { Column } = Table;

type TableType = 'st' | 'sl' | 'dpst' | 'dp' | 'fr';

interface MatchParams {
  tableid: TableType;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

const listStyle: React.CSSProperties = {
  listStyleType: 'disc',
  listStylePosition: 'inside',
};

const Recommend: React.FC<MatchProps> = ({ location, match }) => {
  const query = qs.parse(location.search.substring(1));
  const [filteredValue, setFilteredValue] = useState<any>();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { formatMessage } = useIntl();

  const onChange = (
    tablePagination: TablePaginationConfig,
    filters: Record<string, (string | number | boolean)[] | null>,
    sorter: SorterResult<RecommendProps> | SorterResult<RecommendProps>[],
  ) => {
    const setQuery = (field: any, value: any) => {
      if (field && value) {
        queryObject[field] = value;
      }
    };

    const queryObject: any = {};
    const { current: page } = tablePagination;

    if (page) {
      setQuery('page', page);
    }

    if (Array.isArray(sorter)) {
      const { field, order } = sorter[0];
      setQuery(field, order === 'descend' ? 'desc' : 'asc');
    } else {
      const { field, order } = sorter;
      setQuery(field, order === 'descend' ? 'desc' : 'asc');
    }

    if (filters.level && filters.level.length > 0) {
      setQuery('levelfilter', filters.level.join(','));
      setFilteredValue(filters.level);
    }

    history.push(`${match.params.tableid}?${qs.stringify(queryObject)}`);
  };

  useEffect(() => {
    const onTableChange = async () => {
      const result = await postRequest('/api/recommend', {
        ...query,
        table: match.params.tableid,
      });
      if (result.success) {
        setTotal(result.total);
        setDataSource([...result.source]);
      } else {
        if (result.error) {
          message.error(formatMessage({ id: `error.${result.error}` }));
        } else {
          message.error('Unknown Error.');
        }
      }
      setLoading(false);
    };
    setFilteredValue(undefined);
    setTotal(0);
    setDataSource([]);
    setLoading(true);
    onTableChange();
  }, [location.search, match.params.tableid]);

  if (!checkTable(match.params.tableid)) return <div />;

  return (
    <div>
      <Affix>
        <Menu mode="horizontal" defaultSelectedKeys={[match.params.tableid]}>
          <Item key="st">
            <Link to="st">STELLA</Link>
          </Item>
          <Item key="sl">
            <Link to="sl">SATELLITE</Link>
          </Item>
          <Item key="dpst">
            <Link to="dpst">DP STELLA</Link>
          </Item>
          <Item key="dp">
            <Link to="dp">DP SATELLITE</Link>
          </Item>
          <Item key="fr">
            <Link to="fr">EXOPLANET</Link>
          </Item>
        </Menu>
      </Affix>
      <Collapse defaultActiveKey={['1']}>
        <Panel key="1" header={formatMessage({ id: 'recommendPage.descriptionTitle' })}>
          <ul style={listStyle}>
            {[0, 1, 2, 3].map((i, index) => (
              <li key={index.toString()}>
                {formatMessage({ id: `recommendPage.descriptions.${i}` })}
              </li>
            ))}
          </ul>
        </Panel>
      </Collapse>
      <Table<RecommendProps>
        bordered
        scroll={{ x: 720 }}
        dataSource={dataSource}
        loading={loading}
        pagination={{
          defaultCurrent: query.page ? Number(query.page) : 1,
          pageSize: 50,
          showSizeChanger: false,
          total,
        }}
        rowKey="_id"
        size="small"
        onChange={onChange}
        onHeaderRow={() => {
          return {
            style: { backgroundColor: 'white' },
          };
        }}
      >
        <Column<RecommendProps>
          key="level"
          title="Lv"
          dataIndex="level"
          filters={levelArray[match.params.tableid].map((i) => {
            return {
              text: `${match.params.tableid}${i}`,
              value: i.toString(),
            };
          })}
          onFilter={(value, record) => value === record.level.toString()}
          defaultFilteredValue={(() => {
            if (typeof query.levelfilter === 'string') {
              return query.levelfilter.split(',');
            }
            if (query.levelfilter !== null && typeof query.levelfilter === 'object') {
              return [...query.levelfilter];
            }
          })()}
          filteredValue={filteredValue}
          sorter
          width={80}
        />
        <Column<RecommendProps>
          ellipsis
          key="title"
          title={formatMessage({ id: 'recommendPage.titleTh' })}
          dataIndex="title"
          render={(plaintext, record) => <a href={irUrl(record.md5, 'lr2')}>{record.title}</a>}
          width={240}
        />
        <Column<RecommendProps>
          key="total"
          title="PLAYER"
          dataIndex="total"
          render={(plaintext, record) => ({
            props: {
              style: {
                background: plaintext <= 50 ? 'rgba(255, 0, 0, 0.2)' : 'inherit',
              },
            },
            children: <div>{plaintext}</div>,
          })}
          sorter
          width={80}
        />
        <Column<RecommendProps>
          key="ec"
          title="EASY"
          dataIndex="ec"
          render={(plaintext, record) =>
            record.ec && !Number.isNaN(record.ec)
              ? `${match.params.tableid}${record.ec.toFixed(2)}`
              : null
          }
          sorter
          width={80}
        />
        <Column<RecommendProps>
          key="gc"
          title="CLEAR"
          dataIndex="gc"
          render={(plaintext, record) =>
            record.gc && !Number.isNaN(record.gc)
              ? `${match.params.tableid}${record.gc.toFixed(2)}`
              : null
          }
          sorter
          width={80}
        />
        <Column<RecommendProps>
          key="hc"
          title="HARD"
          dataIndex="hc"
          render={(plaintext, record) =>
            record.hc && !Number.isNaN(record.hc)
              ? `${match.params.tableid}${record.hc.toFixed(2)}`
              : null
          }
          sorter
          width={80}
        />
        <Column<RecommendProps>
          key="fc"
          title="FC"
          dataIndex="fc"
          render={(plaintext, record) =>
            record.fc && !Number.isNaN(record.fc)
              ? `${match.params.tableid}${record.fc.toFixed(2)}`
              : null
          }
          sorter
          width={80}
        />
      </Table>
    </div>
  );
};

export default Recommend;
