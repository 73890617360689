import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Popover, message, Radio } from 'antd';
import {
  CheckOutlined,
  CheckSquareTwoTone,
  MinusSquareTwoTone,
  CloseSquareTwoTone,
  FormOutlined,
} from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { putRequest } from '../utils/ajax';
import Checkbox from 'antd/es/checkbox';

interface MatchProps {
  status: string;
  submission: number;
  table: string;
}

const { Group: CheckboxGroup } = Checkbox;
const { Item } = Form;
const { Group: RadioGroup } = Radio;

const VoteNewForm: React.FC<MatchProps> = ({ status, submission, table }) => {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(false);
  const [checkVote, setCheckVote] = useState(false);

  const { formatMessage } = useIntl();

  const disableStatus: any = {
    notLogin: formatMessage({ id: 'votePage.disable.notLogin' }),
    restricted: formatMessage({ id: 'votePage.disable.restricted' }),
    timeExceed: formatMessage({ id: 'votePage.disable.timeExceed' }),
    voteEnd: formatMessage({ id: 'votePage.disable.voteEnd' }),
  };

  let voteRadio: any;
  if (table !== 'fr') {
    voteRadio = [
      { label: formatMessage({ id: `votePage.vote.yes` }), value: 'yes' },
      { label: formatMessage({ id: `votePage.vote.neutral` }), value: 'neutral' },
      { label: formatMessage({ id: `votePage.vote.no` }), value: 'no' },
    ];
  } else {
    voteRadio = [
      { label: formatMessage({ id: `votePage.vote.yes` }), value: 'yes' },
      { label: formatMessage({ id: `votePage.vote.no` }), value: 'no' },
    ];
  }

  const optionDifficultyRadio: any = [
    { label: formatMessage({ id: `votePage.optionDifficulty.high` }), value: 'high' },
    { label: formatMessage({ id: `votePage.optionDifficulty.mid` }), value: 'mid' },
    { label: formatMessage({ id: `votePage.optionDifficulty.low` }), value: 'low' },
  ];

  const optionEtcCheckbox: any = [
    { label: formatMessage({ id: `votePage.optionEtc.personDiff` }), value: 'personDiff' },
  ];

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, table]);

  const onFinish = async (values: any) => {
    if (!values.vote) {
      message.error(formatMessage({ id: 'error.NOTVALIDVALUE' }));
    } else if (values.comment && values.comment.length > 200) {
      message.error(formatMessage({ id: 'error.TOOLONGCOM' }));
    } else if (values.vote) {
      setDisable(true);
      const result = await putRequest(`/api/vote/vote2/${submission}`, {
        vote: values.vote,
        option: {
          difficulty: values.optionDifficulty,
          personDiff:
            values.optionEtc && values.optionEtc.find((i: any) => i === 'personDiff')
              ? true
              : false,
        },
        comment: values.comment,
        table,
      });

      if (!result.success) {
        if (result.error) message.error(formatMessage({ id: `error.${result.error}` }));
        else message.error('Unknown Error.');
        setDisable(false);
      } else {
        message.success('OK');
        setCheckVote(true);
      }
    } else {
      message.error('Unknown Error.');
    }
  };

  if (checkVote)
    return (
      <Popover content={formatMessage({ id: `votePage.sayThank` })}>
        <span>
          <CheckOutlined style={{ color: '#1890ff', fontSize: '1.4em' }} />
        </span>
      </Popover>
    );

  switch (status) {
    case 'yes':
      return (
        <Popover content={formatMessage({ id: `votePage.voteAnnounce.yes` })}>
          <span>
            <CheckSquareTwoTone style={{ fontSize: '1.6em' }} twoToneColor="#52c41a" />
          </span>
        </Popover>
      );
    case 'neutral':
      return (
        <Popover content={formatMessage({ id: `votePage.voteAnnounce.neutral` })}>
          <span>
            <MinusSquareTwoTone style={{ fontSize: '1.6em' }} twoToneColor="#fce205" />
          </span>
        </Popover>
      );
    case 'no':
      return (
        <Popover content={formatMessage({ id: `votePage.voteAnnounce.no` })}>
          <span>
            <CloseSquareTwoTone style={{ fontSize: '1.6em' }} twoToneColor="#ff0000" />
          </span>
        </Popover>
      );
    case 'timeExceed':
    case 'restricted':
    case 'notLogin':
    case 'voteEnd':
      return (
        <Popover content={disableStatus[status]}>
          <Button size="small" type="primary" disabled>
            <FormOutlined />
          </Button>
        </Popover>
      );
    case 'success':
      return (
        <Popover
          content={
            <span>
              <Form labelCol={{ span: 24, offset: 0 }} form={form} onFinish={onFinish}>
                <Item
                  label={formatMessage({ id: 'votePage.voteLabel' })}
                  style={{ margin: 0 }}
                  name="vote"
                  required
                >
                  <RadioGroup>
                    {voteRadio.map((i: any, index: number) => (
                      <Radio value={i.value} key={`vote-${index}`}>
                        {i.label}
                      </Radio>
                    ))}
                  </RadioGroup>
                </Item>
                <Item
                  label={formatMessage({ id: 'votePage.diffLabel' })}
                  style={{ margin: 0 }}
                  name="optionDifficulty"
                  required
                  initialValue="mid"
                >
                  <RadioGroup>
                    {optionDifficultyRadio.map((i: any, index: number) => (
                      <Radio value={i.value} key={`diff-${index}`}>
                        {i.label}
                      </Radio>
                    ))}
                  </RadioGroup>
                </Item>
                <Item label="기타 평가" style={{ margin: 0 }} name="optionEtc">
                  <CheckboxGroup>
                    {optionEtcCheckbox.map((i: any, index: number) => (
                      <Checkbox value={i.value} key={`etc-${index}`}>
                        {i.label}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </Item>
                <Item style={{ margin: 0 }} name="comment">
                  <Input placeholder={formatMessage({ id: `votePage.comment` })} size="small" />
                </Item>
                <Item>
                  <Button block htmlType="submit" disabled={disable} type="primary" size="small">
                    <CheckOutlined />
                  </Button>
                </Item>
              </Form>
            </span>
          }
          style={{ whiteSpace: 'nowrap' }}
          trigger="click"
        >
          <Button size="small" type="primary">
            <FormOutlined />
          </Button>
        </Popover>
      );
    default:
      return null;
  }
};

export default VoteNewForm;
