import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Popover, Radio, message } from 'antd';
import {
  CheckOutlined,
  CheckSquareTwoTone,
  MinusSquareTwoTone,
  CloseSquareTwoTone,
  FormOutlined,
} from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { putRequest } from '../utils/ajax';
import Paragraph from 'antd/lib/typography/Paragraph';

interface MatchProps {
  originalLevel: number;
  status: string;
  submission: number;
  table: string;
  type: string;
  changeTo: number | undefined;
}

const { Item } = Form;
const { Group: RadioGroup } = Radio;

const VoteForm: React.FC<MatchProps> = ({
  originalLevel,
  status,
  submission,
  table,
  type,
  changeTo,
}) => {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(false);
  const [checkVote, setCheckVote] = useState(false);
  const { formatMessage } = useIntl();

  const disableStatus: any = {
    notLogin: formatMessage({ id: 'votePage.disable.notLogin' }),
    restricted: formatMessage({ id: 'votePage.disable.restricted' }),
    timeExceed: formatMessage({ id: 'votePage.disable.timeExceed' }),
    voteEnd: formatMessage({ id: 'votePage.disable.voteEnd' }),
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, table]);

  const onFinish = async (values: any) => {
    if (!values.vote) {
      message.error(formatMessage({ id: 'error.NOTVALIDVALUE' }));
    } else if (values.comment && values.comment.length > 200) {
      message.error(formatMessage({ id: 'error.TOOLONGCOM' }));
    } else {
      setDisable(true);
      const result = await putRequest(`/api/vote/revision-vote2/${submission}`, {
        ...values,
        table,
      });

      if (!result.success) {
        if (result.error) message.error(formatMessage({ id: `error.${result.error}` }));
        else message.error('Unknown Error.');
        setDisable(false);
      } else {
        message.success('OK');
        setCheckVote(true);
      }
    }
  };

  const onFinishFailed = () => {
    message.error('Unknown Error.');
  };

  if (checkVote)
    return (
      <Popover content={formatMessage({ id: `votePage.sayThank` })}>
        <span>
          <CheckOutlined style={{ color: '#1890ff', fontSize: '1.4em' }} />
        </span>
      </Popover>
    );

  switch (status) {
    case 'yes':
      return (
        <Popover content={formatMessage({ id: `votePage.revisionVoteAnnounce.yes` })}>
          <span>
            <CheckSquareTwoTone style={{ fontSize: '1.6em' }} twoToneColor="#52c41a" />
          </span>
        </Popover>
      );
    case 'neutral':
      return (
        <Popover content={formatMessage({ id: `votePage.revisionVoteAnnounce.neutral` })}>
          <span>
            <MinusSquareTwoTone style={{ fontSize: '1.6em' }} twoToneColor="#fce205" />
          </span>
        </Popover>
      );
    case 'no':
      return (
        <Popover content={formatMessage({ id: `votePage.revisionVoteAnnounce.no` })}>
          <span>
            <CloseSquareTwoTone style={{ fontSize: '1.6em' }} twoToneColor="#ff0000" />
          </span>
        </Popover>
      );
    case 'timeExceed':
    case 'restricted':
    case 'notLogin':
    case 'voteEnd':
      return (
        <Popover content={disableStatus[status]}>
          <Button size="small" type="primary" disabled>
            <FormOutlined />
          </Button>
        </Popover>
      );
    case 'success':
      return (
        <Popover
          content={
            <span>
              <Form
                labelCol={{ span: 24, offset: 0 }}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                {type === 'change' ? (
                  <Paragraph>
                    {changeTo && originalLevel < changeTo
                      ? formatMessage({ id: `votePage.revisionChangeTo.high` })
                      : formatMessage({ id: `votePage.revisionChangeTo.low` })}
                  </Paragraph>
                ) : null}
                {type === 'delete' ? (
                  <Paragraph>{formatMessage({ id: `votePage.revisionDelete` })}</Paragraph>
                ) : null}
                <Item style={{ margin: 0 }} name="vote">
                  <RadioGroup>
                    <Radio value="yes">{formatMessage({ id: `votePage.revisionVote.yes` })}</Radio>
                    {type === 'resurrection' ? (
                      <Radio value="neutral">
                        {formatMessage({ id: `votePage.revisionVote.neutral` })}
                      </Radio>
                    ) : null}
                    <Radio value="no">{formatMessage({ id: `votePage.revisionVote.no` })}</Radio>
                  </RadioGroup>
                </Item>
                <Item style={{ margin: 0 }} name="comment">
                  <Input placeholder={formatMessage({ id: `votePage.comment` })} size="small" />
                </Item>
                <Item style={{ margin: 0 }}>
                  <Button block htmlType="submit" disabled={disable} type="primary" size="small">
                    <CheckOutlined />
                  </Button>
                </Item>
              </Form>
            </span>
          }
          style={{ whiteSpace: 'nowrap' }}
          trigger="click"
        >
          <Button size="small" type="primary">
            <FormOutlined />
          </Button>
        </Popover>
      );
    default:
      return null;
  }
};

export default VoteForm;
