import React, { FC, useEffect, useState } from 'react';
import { Button, Col, message, Row, Space, Switch, Tooltip, Typography } from 'antd';
import { SettingFilled, LogoutOutlined, LoginOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import { defaultUserState, userState } from '../store/atom';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { postRequest } from '../utils/ajax';
import getSingleRestriction from '../utils/getSingleRestriction';
import { Link } from 'react-router-dom';
import Modal from 'antd/lib/modal/Modal';
import Settings from './Settings';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import getDoubleRestriction from '../utils/getDoubleRestriction';

const { Paragraph } = Typography;

const infoDivStyle: React.CSSProperties = {
  color: 'white',
  textAlign: 'center',
};

const MenuHeader: FC = () => {
  const [updateDisable, setUpdateDisable] = useState(false);
  const [user, setUser] = useRecoilState(userState);
  const [modal, setModal] = useState(false);
  const history = useHistory();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { formatMessage } = useIntl();
  const { switcher, themes } = useThemeSwitcher();

  useEffect(() => {
    const item = localStorage.getItem('theme');
    if (item && item === 'dark') {
      setIsDarkMode(true);
      switcher({ theme: themes.dark });
    }
  }, []);

  const onModal = async () => {
    setModal(!modal);
  };

  const onLogout = async () => {
    if (user.success) {
      const result = await postRequest('/api/login/logout');
      if (result) {
        message.success(formatMessage({ id: 'loginInfo.logout' }));
        setUser(defaultUserState);
        history.push('/');
      }
    } else {
      message.error('Unknown Error.');
    }
  };

  const onUpdate = async () => {
    message.info(formatMessage({ id: 'loginInfo.update.0' }));
    setUpdateDisable(true);
    const result = await postRequest('/api/update/');
    if (!result.success) {
      if (result.error) {
        message.error(formatMessage({ id: `error.${result.error}` }));
      } else message.error('Unknown Error.');
    } else {
      message.success(formatMessage({ id: 'loginInfo.update.1' }));
    }
    setUpdateDisable(false);
  };

  const modalCallback = (nickname: string) => {
    setModal(false);
    setUser({ ...user, nickname });
  };

  const toggleTheme = (isChecked: boolean) => {
    setIsDarkMode(isChecked);
    localStorage.setItem('theme', isChecked ? 'dark' : 'light');
    switcher({ theme: isChecked ? themes.dark : themes.light });
  };

  return (
    <div style={{ margin: '10px' }}>
      {user.success ? (
        <>
          <Row justify="center">
            <Col xs={24}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Paragraph style={{ color: 'white' }}>
                  Theme Change:&nbsp;
                  <Switch
                    checkedChildren="Dark"
                    unCheckedChildren="Light"
                    checked={isDarkMode}
                    onChange={toggleTheme}
                  />
                </Paragraph>
                <Tooltip title={formatMessage({ id: 'menu.settings' })}>
                  <Button block size="large" ghost icon={<SettingFilled />} onClick={onModal}>
                    {formatMessage({ id: 'menu.settings' })}
                  </Button>
                </Tooltip>
                <Tooltip title={formatMessage({ id: 'loginInfo.logoutButton' })}>
                  <Button
                    block
                    onClick={onLogout}
                    size="large"
                    ghost
                    danger
                    icon={<LogoutOutlined />}
                  >
                    {formatMessage({ id: 'loginInfo.logoutButton' })}
                  </Button>
                </Tooltip>

                <div style={infoDivStyle}>{user.nickname}</div>
                <div style={infoDivStyle}>{formatMessage({ id: 'loginInfo.restriction' })}</div>
                <div style={infoDivStyle}>
                  SP: {getSingleRestriction(user.restriction, user.restriction2)}, DP: {getDoubleRestriction(user.restriction3, user.restriction4)}
                </div>
                {user.playerName === 'lr2' ? (
                  <Button
                    block
                    size="small"
                    type="primary"
                    onClick={onUpdate}
                    disabled={updateDisable}
                  >
                    {formatMessage({ id: 'loginInfo.updateButton' })}
                  </Button>
                ) : null}
                <Link to={`/user/sl/${user.userlr2id || user.minirid}`}>
                  <Button block size="small">
                    {formatMessage({ id: 'loginInfo.myStellaway' })}
                  </Button>
                </Link>
                <Button
                  block
                  size="small"
                  type="primary"
                  danger
                  style={{ width: '100%' }}
                  onClick={onLogout}
                >
                  {formatMessage({ id: 'loginInfo.logoutButton' })}
                </Button>
              </Space>
            </Col>
          </Row>
          <Modal visible={modal} footer={null} onCancel={onModal}>
            <Settings callback={modalCallback} />
          </Modal>
        </>
      ) : (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Paragraph style={{ color: 'white' }}>
            Theme Change:&nbsp;
            <Switch
              checkedChildren="Dark"
              unCheckedChildren="Light"
              checked={isDarkMode}
              onChange={toggleTheme}
            />
          </Paragraph>
          <Tooltip title="로그인">
            <Link to="/login">
              <Button block type="primary" size="large" ghost icon={<LoginOutlined />}>
                {formatMessage({ id: 'loginInfo.loginButton' })}
              </Button>
            </Link>
          </Tooltip>
        </Space>
      )}
    </div>
  );
};

export default MenuHeader;
