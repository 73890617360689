import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Popover, message } from 'antd';
import {
  CheckOutlined,
  CheckSquareTwoTone,
  MinusSquareTwoTone,
  CloseSquareTwoTone,
  FormOutlined,
} from '@ant-design/icons';
import { isUndefined } from 'util';
import { useIntl } from 'react-intl';
import levelArray from '../utils/levelArray';
import { putRequest } from '../utils/ajax';

interface MatchProps {
  originalLevel: number;
  status: string;
  submission: number;
  table: string;
  type: string;
}

const { Item } = Form;
const { Option } = Select;

const VoteForm: React.FC<MatchProps> = ({ originalLevel, status, submission, table, type }) => {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(false);
  const [checkVote, setCheckVote] = useState(false);
  const { formatMessage } = useIntl();

  const disableStatus: any = {
    notLogin: formatMessage({ id: 'votePage.disable.notLogin' }),
    restricted: formatMessage({ id: 'votePage.disable.restricted' }),
    timeExceed: formatMessage({ id: 'votePage.disable.timeExceed' }),
    voteEnd: formatMessage({ id: 'votePage.disable.voteEnd' }),
  };

  useEffect(() => {
    form.resetFields();
  }, [form, table]);

  const onLevelChange = (value: number | string) => {
    if (type === 'change' || type === 'transfer') {
      if (originalLevel !== value) form.setFieldsValue({ vote: 'yes', level: value });
      else form.setFieldsValue({ vote: 'no', level: value });
    }
  };

  const onFinish = async (values: any) => {
    if (
      ((type === 'change' || type === 'transfer') && isUndefined(values.level)) ||
      (type === 'delete' && !values.vote)
    ) {
      message.error(formatMessage({ id: 'error.NOTVALIDVALUE' }));
    } else if (values.comment && values.comment.length > 200) {
      message.error(formatMessage({ id: 'error.TOOLONGCOM' }));
    } else {
      setDisable(true);
      const result = await putRequest(`/api/vote/revision-vote/${submission}`, {
        ...values,
        table,
      });

      if (!result.success) {
        if (result.error) message.error(formatMessage({ id: `error.${result.error}` }));
        else message.error('Unknown Error.');
        setDisable(false);
      } else {
        message.success('OK');
        setCheckVote(true);
      }
    }
  };

  const onFinishFailed = () => {
    message.error('Unknown Error.');
  };

  if (checkVote)
    return (
      <Popover content={formatMessage({ id: `votePage.sayThank` })}>
        <span>
          <CheckOutlined style={{ color: '#1890ff', fontSize: '1.4em' }} />
        </span>
      </Popover>
    );

  switch (status) {
    case 'yes':
      return (
        <Popover content={formatMessage({ id: `votePage.revisionVoteAnnounce.yes` })}>
          <span>
            <CheckSquareTwoTone style={{ fontSize: '1.6em' }} twoToneColor="#52c41a" />
          </span>
        </Popover>
      );
    case 'neutral':
      return (
        <Popover content={formatMessage({ id: `votePage.revisionVoteAnnounce.neutral` })}>
          <span>
            <MinusSquareTwoTone style={{ fontSize: '1.6em' }} twoToneColor="#fce205" />
          </span>
        </Popover>
      );
    case 'no':
      return (
        <Popover content={formatMessage({ id: `votePage.revisionVoteAnnounce.no` })}>
          <span>
            <CloseSquareTwoTone style={{ fontSize: '1.6em' }} twoToneColor="#ff0000" />
          </span>
        </Popover>
      );
    case 'timeExceed':
    case 'restricted':
    case 'notLogin':
    case 'voteEnd':
      return (
        <Popover content={disableStatus[status]}>
          <Button size="small" type="primary" disabled>
            <FormOutlined />
          </Button>
        </Popover>
      );
    case 'success':
      return (
        <Popover
          content={
            <span>
              <Form form={form} layout="inline" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Item style={{ margin: 0 }} name="level">
                  <Select
                    placeholder="Lv"
                    size="small"
                    style={{ width: '60px' }}
                    disabled={type === 'delete'}
                    onChange={onLevelChange}
                  >
                    <Option value="X" key="X">
                      ?
                    </Option>
                    {table === 'st' ? (
                      <Option value="sl12-" key="0-">
                        0-
                      </Option>
                    ) : null}
                    {table === 'dpst' ? (
                      <Option value="sl10-" key="0-">
                        0-
                      </Option>
                    ) : null}
                    {levelArray[table].map((i) => (
                      <Option value={i} key={i.toString()}>
                        {i}
                      </Option>
                    ))}
                    {table === 'dp' ? (
                      <Option value="st0+" key="10+">
                        10+
                      </Option>
                    ) : null}
                    {table === 'sl' ? (
                      <Option value="st0+" key="12+">
                        12+
                      </Option>
                    ) : null}
                  </Select>
                </Item>
                <Item style={{ margin: 0 }} name="vote">
                  <Select
                    placeholder={formatMessage({
                      id: `votePage.placeholderVote`,
                    })}
                    size="small"
                    style={{ width: '80px' }}
                    disabled={type === 'change' || type === 'transfer'}
                  >
                    <Option value="yes">{formatMessage({ id: `votePage.vote.yes` })}</Option>
                    {type !== 'delete' ? (
                      <Option value="neutral">
                        {formatMessage({
                          id: `votePage.vote.neutral`,
                        })}
                      </Option>
                    ) : null}
                    <Option value="no">{formatMessage({ id: `votePage.vote.no` })}</Option>
                  </Select>
                </Item>
                <Item style={{ margin: 0 }} name="comment">
                  <Input
                    placeholder={formatMessage({ id: `votePage.comment` })}
                    size="small"
                    style={{ width: '150px' }}
                  />
                </Item>
                <Item style={{ margin: 0 }}>
                  <Button htmlType="submit" disabled={disable} type="primary" size="small">
                    <CheckOutlined />
                  </Button>
                </Item>
              </Form>
            </span>
          }
          style={{ whiteSpace: 'nowrap' }}
          trigger="click"
        >
          <Button size="small" type="primary">
            <FormOutlined />
          </Button>
        </Popover>
      );
    default:
      return null;
  }
};

export default VoteForm;
