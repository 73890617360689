import React, { useEffect, useState } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import 'chartjs-plugin-stacked100';
import ClearProps from '../../props/clear';

interface ClearLampProps {
  table: string;
  data: any;
  emulator: string;
}

interface DataSetProps {
  label: string;
  backgroundColor: string;
  data: number[];
}

interface ChartProps {
  labels: string[];
  datasets: DataSetProps[];
}

const defaultChartProps = {
  labels: [],
  datasets: [],
};

const options = {
  maintainAspectRatio: false,
  plugins: {
    stacked100: {
      enable: true,
    },
  },
};

const ClearLamp: React.FC<ClearLampProps> = ({ table, data, emulator }) => {
  const [chartData, setChartData] = useState<ChartProps>(defaultChartProps);
  let emptyLevelArray: number[];
  let typeNumber: number;
  let tableLoopNumber: number;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const ctx2 = canvas.getContext('2d');
  let pfc: CanvasGradient;
  let max: CanvasGradient;
  if (ctx) {
    pfc = ctx.createLinearGradient(0, 0, 0, 30);
    pfc.addColorStop(0, '#ffc');
    pfc.addColorStop(1, '#cff');
  }
  if (ctx2) {
    max = ctx2.createLinearGradient(0, 0, 0, 30);
    max.addColorStop(0, '#cfc');
    max.addColorStop(1, '#cff');
  }

  switch (table) {
    case 'st':
      emptyLevelArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      tableLoopNumber = 12;
      break;
    case 'sl':
      emptyLevelArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      tableLoopNumber = 12;
      break;
    case 'dpst':
      emptyLevelArray = [0, 0, 0, 0, 0, 0, 0];
      tableLoopNumber = 6;
      break;
    case 'dp':
      emptyLevelArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      tableLoopNumber = 10;
      break;
    case 'fr':
      emptyLevelArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      tableLoopNumber = 12;
      break;
    default:
      emptyLevelArray = [];
      tableLoopNumber = 0;
      break;
  }
  const generateDatasets = () => {
    const object: ChartProps = {
      labels: [],
      datasets: [],
    };
    let datasets: any;
    if (emulator === 'lr2') {
      typeNumber = 5;
      datasets = [
        {
          label: 'FC',
          backgroundColor: '#cff',
          data: [...emptyLevelArray],
        },
        {
          label: 'HARD',
          backgroundColor: '#fcc',
          data: [...emptyLevelArray],
        },
        {
          label: 'NORMAL',
          backgroundColor: '#8cf',
          data: [...emptyLevelArray],
        },
        {
          label: 'EASY',
          backgroundColor: '#cfc',
          data: [...emptyLevelArray],
        },
        {
          label: 'FAILED',
          backgroundColor: '#ccc',
          data: [...emptyLevelArray],
        },
        {
          label: 'NOPLAY',
          backgroundColor: 'white',
          data: [...emptyLevelArray],
        },
      ];
    } else {
      typeNumber = 10;
      datasets = [
        {
          label: 'MAX',
          backgroundColor: max || 'red',
          data: [...emptyLevelArray],
        },
        {
          label: 'PFC',
          backgroundColor: pfc || 'yellow',
          data: [...emptyLevelArray],
        },
        {
          label: 'FC',
          backgroundColor: '#cff',
          data: [...emptyLevelArray],
        },
        {
          label: 'EXHARD',
          backgroundColor: '#ffc',
          data: [...emptyLevelArray],
        },
        {
          label: 'HARD',
          backgroundColor: '#fcc',
          data: [...emptyLevelArray],
        },
        {
          label: 'NORMAL',
          backgroundColor: '#8cf',
          data: [...emptyLevelArray],
        },
        {
          label: 'EASY',
          backgroundColor: '#cfc',
          data: [...emptyLevelArray],
        },
        {
          label: 'LASSIST',
          backgroundColor: '#fcf',
          data: [...emptyLevelArray],
        },
        {
          label: 'ASSIST',
          backgroundColor: '#f8f',
          data: [...emptyLevelArray],
        },
        {
          label: 'FAILED',
          backgroundColor: '#ccc',
          data: [...emptyLevelArray],
        },
        {
          label: 'NOPLAY',
          backgroundColor: 'white',
          data: [...emptyLevelArray],
        },
      ];
    }
    for (let i = 0; i <= tableLoopNumber; i += 1) {
      if (table === 'dpst') {
        object.labels.push(`st${i}`);
      } else if (table === 'dp') {
        object.labels.push(`sl${i}`);
      } else {
        object.labels.push(`${table}${i}`);
      }
    }
    data.forEach((i: ClearProps) => {
      if (i.clear) {
        datasets[typeNumber - i.clear].data[i.level] += 1;
      } else {
        datasets[typeNumber].data[i.level] += 1;
      }
    });
    object.datasets = datasets;
    return object;
  };

  useEffect(() => {
    setChartData(generateDatasets());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, emulator]);

  return (
    <div style={{ width: '100%', height: 300 }}>
      <HorizontalBar data={chartData} options={options} />
    </div>
  );
};

export default ClearLamp;
