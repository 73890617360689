interface LevelArray {
  [index: string]: number[];
}

const levelArray: LevelArray = {
  st: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  sl: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  dpst: [0, 1, 2, 3, 4, 5, 6],
  dp: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  fr: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
};

export default levelArray;
