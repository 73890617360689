import React, { useState, useEffect } from 'react';
import { Affix, Input, Menu, Table, message, TablePaginationConfig } from 'antd';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ClearProps from '../props/clear';
import StellawayProps from '../props/stellaway';
import checkTable from '../utils/checkTable';
import levelArray from '../utils/levelArray';
import irUrl from '../utils/irUrl';
import customSort from '../utils/customSort';
import { postRequest } from '../utils/ajax';

const { Search } = Input;
const { Item } = Menu;
const { Column } = Table;

type TableType = 'st' | 'sl' | 'dpst' | 'dp' | 'fr';

interface MatchParams {
  tableid: TableType;
  userid: string;
  rivalid: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

interface RivalProps extends ClearProps {
  rPercent: number;
  rMinbp: number;
  rScore: number;
  rClear: number;
}

interface UserProps {
  dan: number;
  rDan: number;
  dan2: number;
  rDan2: number;
  nickname: string;
  rNickname: string;
  playerName: string;
  stairway: ClearProps[];
  stairway2: ClearProps[];
  stairway3: ClearProps[];
  stairway4: ClearProps[];
  stairway5: ClearProps[];
  rStairway: ClearProps[];
  rStairway2: ClearProps[];
  rStairway3: ClearProps[];
  rStairway4: ClearProps[];
  rStairway5: ClearProps[];
  playData: StellawayProps;
  playData2: StellawayProps;
  playData3: StellawayProps;
  playData4: StellawayProps;
  playData5: StellawayProps;
  rPlayData: StellawayProps;
  rPlayData2: StellawayProps;
  rPlayData3: StellawayProps;
  rPlayData4: StellawayProps;
  rPlayData5: StellawayProps;
}

const defaultUserProps: UserProps = {
  dan: -1,
  rDan: -1,
  dan2: -1,
  rDan2: -1,
  nickname: '',
  rNickname: '',
  playerName: '',
  stairway: [],
  stairway2: [],
  stairway3: [],
  stairway4: [],
  stairway5: [],
  rStairway: [],
  rStairway2: [],
  rStairway3: [],
  rStairway4: [],
  rStairway5: [],
  playData: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  rPlayData: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  playData2: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  rPlayData2: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  playData3: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  rPlayData3: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  playData4: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  rPlayData4: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  playData5: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  rPlayData5: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
};

const minirRegex = /^([0-9a-f]{8})-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{12})$/;

const User: React.FC<MatchProps> = ({ match }) => {
  const [clearData, setClearData] = useState<RivalProps[]>([]);
  const [userData, setUserData] = useState<UserProps>(defaultUserProps);
  const [loading, setLoading] = useState(false);
  const [filteredValue, setFilteredValue] = useState<string[]>([]);
  const [user, setUser] = useState('');
  const [rival, setRival] = useState('');

  const history = useHistory();
  const { formatMessage } = useIntl();

  useEffect(() => {
    (async () => {
      const onUserChange = async () => {
        const result = await postRequest(
          `/api/user/${match.params.userid}/${match.params.rivalid}`,
        );

        if (!result.success) {
          if (result.error) {
            message.error(formatMessage({ id: `error.${result.error}` }));
          } else message.error('Unknown Error.');
        } else {
          const {
            dan,
            rDan,
            dan2,
            rDan2,
            nickname,
            rNickname,
            playData,
            playData2,
            playData3,
            playData4,
            playData5,
            rPlayData,
            rPlayData2,
            rPlayData3,
            rPlayData4,
            rPlayData5,
            playerName,
            stairway,
            stairway2,
            stairway3,
            stairway4,
            stairway5,
            rStairway,
            rStairway2,
            rStairway3,
            rStairway4,
            rStairway5,
          } = result;

          setUserData({
            dan,
            rDan,
            dan2,
            rDan2,
            nickname,
            rNickname,
            playData,
            playData2,
            playData3,
            playData4,
            playData5,
            rPlayData,
            rPlayData2,
            rPlayData3,
            rPlayData4,
            rPlayData5,
            playerName,
            stairway,
            stairway2,
            stairway3,
            stairway4,
            stairway5,
            rStairway,
            rStairway2,
            rStairway3,
            rStairway4,
            rStairway5,
          });
        }
      };
      const onTableChange = async () => {
        const result = await postRequest('/api/user/list', {
          table: match.params.tableid,
        });

        if (!result.success) {
          if (result.error) {
            message.error(formatMessage({ id: `error.${result.error}` }));
          } else message.error('Unknown Error.');
        } else {
          if (match.params.tableid === 'st') {
            const datasets = result.songs.map((i: any) => {
              const rivalObj = userData.rStairway.find(
                (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
              );
              const obj = {
                ...i,
                ...userData.stairway.find(
                  (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
                ),
              };
              if (rivalObj) {
                const { percent: rPercent, minbp: rMinbp, score: rScore, clear: rClear } = rivalObj;
                return {
                  ...obj,
                  rPercent,
                  rMinbp,
                  rScore,
                  rClear,
                };
              }
              return obj;
            });
            setClearData(datasets);
          }
          if (match.params.tableid === 'sl') {
            const datasets = result.songs.map((i: any) => {
              const rivalObj = userData.rStairway2.find(
                (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
              );
              const obj = {
                ...i,
                ...userData.stairway2.find(
                  (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
                ),
              };
              if (rivalObj) {
                const { percent: rPercent, minbp: rMinbp, score: rScore, clear: rClear } = rivalObj;
                return {
                  ...obj,
                  rPercent,
                  rMinbp,
                  rScore,
                  rClear,
                };
              }
              return obj;
            });
            setClearData(datasets);
          }
          if (match.params.tableid === 'dpst') {
            const datasets = result.songs.map((i: any) => {
              const rivalObj = userData.rStairway3.find(
                (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
              );
              const obj = {
                ...i,
                ...userData.stairway3.find(
                  (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
                ),
              };
              if (rivalObj) {
                const { percent: rPercent, minbp: rMinbp, score: rScore, clear: rClear } = rivalObj;
                return {
                  ...obj,
                  rPercent,
                  rMinbp,
                  rScore,
                  rClear,
                };
              }
              return obj;
            });
            setClearData(datasets);
          }
          if (match.params.tableid === 'dp') {
            const datasets = result.songs.map((i: any) => {
              const rivalObj = userData.rStairway4.find(
                (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
              );
              const obj = {
                ...i,
                ...userData.stairway4.find(
                  (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
                ),
              };
              if (rivalObj) {
                const { percent: rPercent, minbp: rMinbp, score: rScore, clear: rClear } = rivalObj;
                return {
                  ...obj,
                  rPercent,
                  rMinbp,
                  rScore,
                  rClear,
                };
              }
              return obj;
            });
            setClearData(datasets);
          }
          if (match.params.tableid === 'fr') {
            const datasets = result.songs.map((i: any) => {
              const rivalObj = userData.rStairway5.find(
                (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
              );
              const obj = {
                ...i,
                ...userData.stairway5.find(
                  (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
                ),
              };
              if (rivalObj) {
                const { percent: rPercent, minbp: rMinbp, score: rScore, clear: rClear } = rivalObj;
                return {
                  ...obj,
                  rPercent,
                  rMinbp,
                  rScore,
                  rClear,
                };
              }
              return obj;
            });
            setClearData(datasets);
          }
        }
      };
      setLoading(true);
      setClearData([]);
      setFilteredValue([]);
      if (user !== match.params.userid || rival !== match.params.rivalid) {
        await onUserChange();
        setUser(match.params.userid);
        setRival(match.params.rivalid);
      }
      await onTableChange();
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    match.params.tableid,
    match.params.userid,
    match.params.rivalid,
    userData.stairway,
    userData.stairway2,
    userData.stairway3,
    userData.stairway4,
    userData.rStairway,
    userData.rStairway2,
    userData.rStairway3,
    userData.rStairway4,
  ]);

  const onSearch = (value: string) => {
    if (value) {
      history.push(value);
    }
  };

  const onChange = (
    pagination: TablePaginationConfig,
    filters: Partial<Record<keyof ClearProps, string[]>>,
  ) => {
    setFilteredValue(filters.level || []);
  };

  if (!checkTable(match.params.tableid)) return <div />;

  return (
    <div>
      <Affix>
        <Menu mode="horizontal" defaultSelectedKeys={[match.params.tableid]}>
          <Item key="st">
            <Link to={`../../st/${match.params.userid}/${match.params.rivalid}`}>STELLA</Link>
          </Item>
          <Item key="sl">
            <Link to={`../../sl/${match.params.userid}/${match.params.rivalid}`}>SATELLITE</Link>
          </Item>
          <Item key="dpst">
            <Link to={`../../dpst/${match.params.userid}/${match.params.rivalid}`}>DP STELLA</Link>
          </Item>
          <Item key="dp">
            <Link to={`../../dp/${match.params.userid}/${match.params.rivalid}`}>DP SATELLITE</Link>
          </Item>
        </Menu>
      </Affix>
      <div className="normal-container">
        <div className="framed text-center">
          <h1>
            {match.params.tableid === 'st' || match.params.tableid === 'sl' ? (
              <>
                <span className={`skill${userData.dan}`}>
                  <Link to={`../${match.params.userid}`} style={{ color: 'inherit' }}>
                    {userData.nickname}
                  </Link>
                </span>
                &nbsp;vs&nbsp;
                <span className={`skill${userData.rDan}`}>
                  <Link to={`../${match.params.rivalid}`} style={{ color: 'inherit' }}>
                    {userData.rNickname}
                  </Link>
                </span>
              </>
            ) : (
              <>
                <span className={`skill${(Number(userData.dan2) + 12).toString()}`}>
                  <Link to={`../${match.params.userid}`} style={{ color: 'inherit' }}>
                    {userData.nickname}
                  </Link>
                </span>
                &nbsp;vs&nbsp;
                <span className={`skill${(Number(userData.rDan2) + 12).toString()}`}>
                  <Link to={`../${match.params.rivalid}`} style={{ color: 'inherit' }}>
                    {userData.rNickname}
                  </Link>
                </span>
              </>
            )}
          </h1>
          {match.params.tableid === 'st' ? (
            <h2>
              Lv.{userData.playData.userLevel} vs Lv.
              {userData.rPlayData.userLevel}
            </h2>
          ) : null}
          {match.params.tableid === 'sl' ? (
            <h2>
              Lv.{userData.playData2.userLevel} vs Lv.
              {userData.rPlayData2.userLevel}
            </h2>
          ) : null}
          {match.params.tableid === 'dpst' ? (
            <h2>
              Lv.{userData.playData3.userLevel} vs Lv.
              {userData.rPlayData3.userLevel}
            </h2>
          ) : null}
          {match.params.tableid === 'dp' ? (
            <h2>
              Lv.{userData.playData4.userLevel} vs Lv.
              {userData.rPlayData4.userLevel}
            </h2>
          ) : null}
          {match.params.tableid === 'fr' ? (
            <h2>
              Lv.{userData.playData5.userLevel} vs Lv.
              {userData.rPlayData5.userLevel}
            </h2>
          ) : null}
        </div>
      </div>
      <div style={{ textAlign: 'right' }}>
        <Search
          placeholder={formatMessage({ id: 'userPage.rivalSearchPlaceholder' })}
          onSearch={onSearch}
          enterButton
          loading={loading}
          style={{
            width: '50%',
          }}
        />
      </div>
      <Table<RivalProps>
        bordered
        scroll={{ x: 720 }}
        dataSource={clearData}
        loading={loading}
        pagination={false}
        rowKey="_id"
        size="small"
        onChange={onChange}
        onHeaderRow={() => {
          return {
            style: { backgroundColor: 'white' },
          };
        }}
      >
        <Column<RivalProps>
          key="level"
          title="Lv"
          dataIndex="level"
          filters={levelArray[match.params.tableid].map((i) => {
            return {
              text: `${match.params.tableid}${i.toString()}`,
              value: i.toString(),
            };
          })}
          onFilter={(value, record) => value === record.level.toString()}
          filteredValue={filteredValue}
          sorter={customSort('level')}
          onCell={(record) => {
            return {
              className: `${userData.playerName}-clear-${record.clear}`,
            };
          }}
          width={80}
        />
        <Column<RivalProps>
          ellipsis
          key="title"
          title="Title"
          dataIndex="title"
          render={(plaintext, record) => (
            <a
              href={
                userData.playerName === 'lr2'
                  ? irUrl(record.md5, 'lr2')
                  : irUrl(record.sha256, 'beatoraja')
              }
            >
              {record.title}
            </a>
          )}
          onCell={(record) => {
            return {
              className: `${userData.playerName}-clear-${record.clear}`,
            };
          }}
          width={240}
        />
        <Column<RivalProps>
          key="rClear"
          title="R."
          dataIndex="rClear"
          render={() => 'R'}
          align="center"
          onCell={(record) => {
            return {
              className: `${userData.playerName}-clear-${record.rClear}`,
            };
          }}
          width={40}
        />
        <Column<RivalProps>
          key="percent"
          title="%"
          dataIndex="percent"
          render={(plaintext, record) => record.percent?.toFixed(2)}
          sorter={customSort('percent')}
          className="white-cell"
          width={80}
        />
        <Column<RivalProps>
          key="cPercent"
          title="Diff"
          render={(plaintext, record) => {
            const percentDiff = record.percent - record.rPercent;
            const style: any = {
              fontWeight: 'bold',
            };
            if (Number.isNaN(percentDiff)) return <span />;
            if (percentDiff > 0) style.color = '#0080ff';
            else if (percentDiff < 0) style.color = '#c21807';
            else style.color = '#999';
            return <span style={style}>{percentDiff.toFixed(2)}</span>;
          }}
          sorter={(a, b) => {
            const aDiff = a.percent - a.rPercent;
            const bDiff = b.percent - b.rPercent;
            if (Number.isNaN(aDiff) && Number.isNaN(bDiff)) return -1;
            if (Number.isNaN(aDiff)) return -1;
            if (Number.isNaN(bDiff)) return 1;
            return a.percent - a.rPercent - (b.percent - b.rPercent);
          }}
          className="white-cell"
          width={80}
        />
        <Column<RivalProps>
          key="rPercent"
          title="R.%"
          dataIndex="rPercent"
          render={(plaintext, record) => record.rPercent?.toFixed(2)}
          sorter={customSort('rPercent')}
          className="white-cell"
          width={100}
        />
        {!minirRegex.test(match.params.userid) ? (
          <Column<RivalProps>
            key="minbp"
            title="BP"
            dataIndex="minbp"
            sorter={customSort('minbp')}
            className="white-cell"
            width={80}
          />
        ) : null}
        {!minirRegex.test(match.params.userid) ? (
          <Column<RivalProps>
            key="cMinbp"
            title="Diff"
            render={(plaintext, record) => {
              const minbpDiff = record.minbp - record.rMinbp;
              const style: any = {
                fontWeight: 'bold',
              };
              if (Number.isNaN(minbpDiff)) return <span />;
              if (minbpDiff > 0) style.color = '#c21807';
              else if (minbpDiff < 0) style.color = '#0080ff';
              else style.color = '#999';
              return <span style={style}>{minbpDiff}</span>;
            }}
            sorter={(a, b) => {
              const aDiff = a.minbp - a.rMinbp;
              const bDiff = b.minbp - b.rMinbp;
              if (Number.isNaN(aDiff) && Number.isNaN(bDiff)) return -1;
              if (Number.isNaN(aDiff)) return -1;
              if (Number.isNaN(bDiff)) return 1;
              return a.minbp - a.rMinbp - (b.minbp - b.rMinbp);
            }}
            className="white-cell"
            width={80}
          />
        ) : null}
        {!minirRegex.test(match.params.userid) ? (
          <Column<RivalProps>
            key="rMinbp"
            title="R.BP"
            dataIndex="rMinbp"
            sorter={customSort('rMinbp')}
            className="white-cell"
            width={80}
          />
        ) : null}
        <Column<RivalProps>
          key="score"
          title="EX"
          dataIndex="score"
          sorter={customSort('score')}
          className="white-cell"
          width={80}
        />
        <Column<RivalProps>
          key="cScore"
          title="Diff"
          render={(plaintext, record) => {
            const scoreDiff = record.score - record.rScore;
            const style: any = {
              fontWeight: 'bold',
            };
            if (Number.isNaN(scoreDiff)) return <span />;
            if (scoreDiff > 0) style.color = '#0080ff';
            else if (scoreDiff < 0) style.color = '#c21807';
            else style.color = '#999';
            return <span style={style}>{scoreDiff}</span>;
          }}
          sorter={(a, b) => {
            const aDiff = a.score - a.rScore;
            const bDiff = b.score - b.rScore;
            if (Number.isNaN(aDiff) && Number.isNaN(bDiff)) return -1;
            if (Number.isNaN(aDiff)) return -1;
            if (Number.isNaN(bDiff)) return 1;
            return a.score - a.rScore - (b.score - b.rScore);
          }}
          className="white-cell"
          width={80}
        />
        <Column<RivalProps>
          key="rScore"
          title="R.EX"
          dataIndex="rScore"
          sorter={customSort('rScore')}
          className="white-cell"
          width={80}
        />
      </Table>
    </div>
  );
};

export default User;
