const getTableName = (param: string) => {
  switch (param) {
    case 'st':
      return 'STELLA';
    case 'sl':
      return 'SATELLITE';
    case 'dpst':
      return 'DP STELLA';
    case 'dp':
      return 'DP SATELLITE';
    case 'fr':
      return 'EXOPLANET';
    default:
      return null;
  }
};

export default getTableName;
