/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Alert, Button, Form, Input, InputNumber, message } from 'antd';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { userState } from '../../store/atom';
import { postRequest } from '../../utils/ajax';

const { Item } = Form;

type ValidateStatusType = '' | 'error' | 'success' | 'warning' | 'validating';

interface ValidateStatusProps {
  userid: ValidateStatusType;
  userpw: ValidateStatusType;
  userpwAgain: ValidateStatusType;
  userlr2id: ValidateStatusType;
  nickname: ValidateStatusType;
  lr2auth: ValidateStatusType;
  code: ValidateStatusType;
}

interface ErrorFieldsProps {
  userid?: string;
  userpw?: string;
  userpwAgain?: string;
  userlr2id?: string;
  nickname?: string;
  lr2auth?: string;
  code?: string;
}

const defaultValidateStatusProps: ValidateStatusProps = {
  userid: '',
  userpw: '',
  userpwAgain: '',
  userlr2id: '',
  nickname: '',
  lr2auth: '',
  code: '',
};

const SignUpLr2: React.FC = () => {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(false);
  const [validateStatus, setValidateStatus] = useState<ValidateStatusProps>(
    defaultValidateStatusProps,
  );
  const [errorFields, setErrorFields] = useState<ErrorFieldsProps>({});

  const user = useRecoilValue(userState);
  const history = useHistory();
  const { formatMessage } = useIntl();

  const onCheck = async (type: string) => {
    const values = form.getFieldValue(type);
    switch (type) {
      case 'userid':
        setValidateStatus({ ...validateStatus, userid: 'validating' });
        setErrorFields({ ...errorFields, userid: '' });
        if (values && /^[a-zA-Z0-9-_]{6,18}$/.test(values)) {
          const result = await postRequest('/api/signup/check', {
            userid: values,
          });
          if (!result.success) {
            setValidateStatus({ ...validateStatus, userid: 'error' });
            setErrorFields({
              ...errorFields,
              userid: formatMessage({ id: `error.${result.error}` }),
            });
          } else {
            setValidateStatus({ ...validateStatus, userid: 'success' });
          }
        } else if (values && (values.length < 6 || values.length > 18)) {
          setValidateStatus({ ...validateStatus, userid: 'error' });
          setErrorFields({
            ...errorFields,
            userid: formatMessage({ id: 'error.NOTVALIDID' }),
          });
        } else {
          setValidateStatus({ ...validateStatus, userid: 'error' });
          setErrorFields({
            ...errorFields,
            userid: formatMessage({ id: 'error.NOTVALIDID2' }),
          });
        }
        break;
      case 'userpw':
        setValidateStatus({ ...validateStatus, userpw: 'validating' });
        setErrorFields({ ...errorFields, userpw: '' });
        if (values && /^[a-zA-Z0-9-_]{8,24}$/.test(values)) {
          setValidateStatus({ ...validateStatus, userpw: 'success' });
        } else if (values && (values.length < 8 || values.length > 24)) {
          setValidateStatus({ ...validateStatus, userpw: 'error' });
          setErrorFields({
            ...errorFields,
            userpw: formatMessage({ id: 'error.NOTVALIDPW' }),
          });
        } else {
          setValidateStatus({ ...validateStatus, userpw: 'error' });
          setErrorFields({
            ...errorFields,
            userpw: formatMessage({ id: 'error.NOTVALIDPW2' }),
          });
        }
        break;
      case 'userpwAgain':
        setValidateStatus({ ...validateStatus, userpwAgain: 'validating' });
        setErrorFields({ ...errorFields, userpwAgain: '' });
        if (
          values &&
          /^[a-zA-Z0-9-_]{8,24}$/.test(values) &&
          form.getFieldValue('userpw') === values
        ) {
          setValidateStatus({ ...validateStatus, userpwAgain: 'success' });
        } else {
          setValidateStatus({ ...validateStatus, userpwAgain: 'error' });
          setErrorFields({
            ...errorFields,
            userpwAgain: formatMessage({ id: 'error.NOTEQUALPW' }),
          });
        }
        break;
      case 'userlr2id':
        setValidateStatus({ ...validateStatus, userlr2id: 'validating' });
        setErrorFields({ ...errorFields, userlr2id: '' });
        if (values && /^[0-9]{1,6}$/.test(values)) {
          const result = await postRequest('/api/signup/check', {
            userlr2id: values,
          });
          if (!result.success) {
            setValidateStatus({ ...validateStatus, userlr2id: 'error' });
            setErrorFields({
              ...errorFields,
              userlr2id: formatMessage({ id: `error.${result.error}` }),
            });
          } else {
            setValidateStatus({ ...validateStatus, userlr2id: 'success' });
          }
        } else {
          setValidateStatus({ ...validateStatus, userlr2id: 'error' });
          setErrorFields({
            ...errorFields,
            userlr2id: formatMessage({ id: 'error.NOTVALIDLR2ID' }),
          });
        }
        break;
      case 'nickname':
        setValidateStatus({ ...validateStatus, nickname: 'validating' });
        setErrorFields({ ...errorFields, nickname: '' });
        if (values && /^\S{2,12}$/.test(values)) {
          const result = await postRequest('/api/signup/check', {
            nickname: values,
          });
          if (!result.success) {
            setValidateStatus({ ...validateStatus, nickname: 'error' });
            setErrorFields({
              ...errorFields,
              nickname: formatMessage({ id: `error.${result.error}` }),
            });
          } else {
            setValidateStatus({ ...validateStatus, nickname: 'success' });
          }
        } else if (values && (values.length < 2 || values.length > 12)) {
          setValidateStatus({ ...validateStatus, nickname: 'error' });
          setErrorFields({
            ...errorFields,
            nickname: formatMessage({ id: 'error.NOTVALIDNICK' }),
          });
        } else {
          setValidateStatus({ ...validateStatus, nickname: 'error' });
          setErrorFields({
            ...errorFields,
            nickname: formatMessage({ id: 'error.NOTVALIDNICK2' }),
          });
        }
        break;
      case 'lr2auth':
        setValidateStatus({ ...validateStatus, lr2auth: '' });
        setValidateStatus({ ...validateStatus, code: '' });
        setErrorFields({ ...errorFields, lr2auth: '' });
        setErrorFields({ ...errorFields, code: '' });
        if (
          validateStatus.userlr2id === 'success' &&
          /^[0-9]{1,6}$/.test(form.getFieldValue('userlr2id'))
        ) {
          const result = await postRequest('/api/signup/check', {
            lr2auth: form.getFieldValue('userlr2id'),
          });
          if (!result.success) {
            setValidateStatus({ ...validateStatus, lr2auth: 'error' });
            setErrorFields({
              ...errorFields,
              lr2auth: formatMessage({ id: `error.${result.error}` }),
            });
          } else {
            setValidateStatus({ ...validateStatus, lr2auth: 'success' });
            form.setFieldsValue({
              code: `$authcode${result.token}$authcode`,
            });
            setErrorFields({
              ...errorFields,
              lr2auth: formatMessage({ id: `error.${result.message}` }),
            });
          }
        } else {
          setValidateStatus({ ...validateStatus, lr2auth: 'error' });
          setValidateStatus({ ...validateStatus, userlr2id: 'error' });
          setErrorFields({
            ...errorFields,
            lr2auth: formatMessage({ id: `error.NOTVALIDLR2ID` }),
          });
          setErrorFields({
            ...errorFields,
            userlr2id: formatMessage({ id: `error.NOTVALIDLR2ID` }),
          });
        }
        break;
      default:
        break;
    }
  };

  const onFinish = async (values: any) => {
    const validateFields: Array<keyof ValidateStatusProps> = [
      'userid',
      'userpw',
      'userpwAgain',
      'userlr2id',
      'nickname',
    ];
    if (
      validateFields.every((i) => validateStatus[i] === 'success' && values[i]) &&
      values.userpw === values.userpwAgain &&
      validateStatus.lr2auth === 'success'
    ) {
      setDisable(true);
      const result = await postRequest('/api/signup/submit', values);
      if (!result.success) {
        message.error(result.error);
        setDisable(false);
      } else {
        history.push('/');
        message.success(formatMessage({ id: 'signupPage.success' }));
      }
    } else {
      message.error(formatMessage({ id: 'error.NOTINFO' }));
    }
  };

  const onFinishFailed = (err: any) => {
    const errorObject: any = {};
    if (err.userid) errorObject.userid = 'error';
    if (err.userpw) errorObject.userpw = 'error';
    if (err.userpwAgain) errorObject.userpwAgain = 'error';
    if (err.nickname) errorObject.nickname = 'error';
    if (err.userlr2id) errorObject.userlr2id = 'error';
    setValidateStatus({ ...validateStatus, ...errorObject });
  };

  if (user.userid) return <div />;

  return (
    <div className="normal-container">
      <Alert type="warning" message={formatMessage({ id: 'signupPage.warning' })} />
      <Form
        form={form}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 8 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Item
          label={formatMessage({ id: 'signupPage.placeholders.userid' })}
          name="userid"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'signupPage.emptyError.userid',
              }),
            },
          ]}
          hasFeedback
          validateStatus={validateStatus.userid}
          help={errorFields.userid}
        >
          <Input
            autoComplete="off"
            placeholder={formatMessage({
              id: 'signupPage.placeholders.userid',
            })}
            onBlur={() => onCheck('userid')}
          />
        </Item>
        <Item
          label={formatMessage({ id: 'signupPage.placeholders.userpw' })}
          name="userpw"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'signupPage.emptyError.userpw',
              }),
            },
          ]}
          hasFeedback
          validateStatus={validateStatus.userpw}
          help={errorFields.userpw}
        >
          <Input
            placeholder={formatMessage({
              id: 'signupPage.placeholders.userpw',
            })}
            type="password"
            onBlur={() => onCheck('userpw')}
          />
        </Item>
        <Item
          label={formatMessage({ id: 'signupPage.placeholders.userpwAgain' })}
          name="userpwAgain"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'signupPage.emptyError.userpwAgain',
              }),
            },
          ]}
          hasFeedback
          validateStatus={validateStatus.userpwAgain}
          help={errorFields.userpwAgain}
        >
          <Input
            placeholder={formatMessage({
              id: 'signupPage.placeholders.userpwAgain',
            })}
            type="password"
            onBlur={() => onCheck('userpwAgain')}
          />
        </Item>
        <Item
          label={formatMessage({ id: 'signupPage.placeholders.nickname' })}
          name="nickname"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'signupPage.emptyError.nickname',
              }),
            },
          ]}
          hasFeedback
          validateStatus={validateStatus.nickname}
          help={errorFields.nickname}
        >
          <Input
            autoComplete="off"
            placeholder={formatMessage({
              id: 'signupPage.placeholders.nickname',
            })}
            onBlur={() => onCheck('nickname')}
          />
        </Item>
        <Item
          label={formatMessage({ id: 'signupPage.placeholders.userlr2id' })}
          name="userlr2id"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'signupPage.emptyError.userlr2id',
              }),
            },
          ]}
          hasFeedback
          validateStatus={validateStatus.userlr2id}
          help={errorFields.userlr2id}
        >
          <InputNumber
            min={1}
            max={999999}
            placeholder={formatMessage({
              id: 'signupPage.placeholders.userlr2id',
            })}
            onBlur={() => onCheck('userlr2id')}
            style={{ width: '100%' }}
          />
        </Item>
        <Item
          wrapperCol={{
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }}
        >
          <Button
            disabled={validateStatus.lr2auth === 'validating'}
            onClick={() => onCheck('lr2auth')}
          >
            {formatMessage({ id: 'signupPage.createAuthButton' })}
          </Button>
          <span>{errorFields.lr2auth}</span>
        </Item>
        <Item
          label={formatMessage({ id: 'signupPage.placeholders.lr2auth' })}
          name="code"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'signupPage.emptyError.lr2auth',
              }),
            },
          ]}
          hasFeedback
          validateStatus={validateStatus.code}
          help={errorFields.code}
        >
          <Input
            autoComplete="off"
            placeholder={formatMessage({
              id: 'signupPage.placeholders.lr2auth',
            })}
            readOnly
            disabled={validateStatus.lr2auth !== 'success'}
          />
        </Item>
        <Item
          wrapperCol={{
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }}
        >
          <Button type="primary" htmlType="submit" disabled={disable}>
            {formatMessage({ id: 'signupPage.submitButton' })}
          </Button>
        </Item>
      </Form>
    </div>
  );
};

export default SignUpLr2;
