import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import {
  Affix,
  Input,
  Menu,
  Table,
  Tag,
  message,
  TablePaginationConfig,
  Space,
  Popover,
} from 'antd';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleTwoTone,
  LockOutlined,
} from '@ant-design/icons';
import qs from 'querystring';
import dayjs from 'dayjs';
import { isNull } from 'util';
import { useIntl } from 'react-intl';
import checkTable from '../utils/checkTable';
import levelArray from '../utils/levelArray';
import SongProps from '../props/song';
import { postRequest } from '../utils/ajax';
import { SorterResult } from 'antd/lib/table/interface';
import irUrl from '../utils/irUrl';

const { Search } = Input;
const { Item } = Menu;
const { Column } = Table;

type TableType = 'st' | 'sl' | 'dp' | 'dpst' | 'fr';

interface MatchParams {
  tableid: TableType;
  playerid: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

const Submission: React.FC<MatchProps> = ({ location, match }) => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filteredValue, setFilteredValue] = useState<any>();

  const query = qs.parse(location.search.substring(1));
  const history = useHistory();
  const { formatMessage } = useIntl();

  const onChange = (
    tablePagination: TablePaginationConfig,
    filters: Record<string, (string | number | boolean)[] | null>,
    sorter: SorterResult<SongProps> | SorterResult<SongProps>[],
  ) => {
    const setQuery = (field: any, value: any) => {
      if (field && value) {
        queryObject[field] = value;
      }
    };

    const queryObject: any = {};
    const { current: page } = tablePagination;

    if (page) {
      setQuery('page', page);
    }

    if (query.search) {
      setQuery('search', query.search);
    }

    if (Array.isArray(sorter)) {
      const { field, order } = sorter[0];
      setQuery(field, order === 'descend' ? 'desc' : 'asc');
    } else {
      const { field, order } = sorter;
      setQuery(field, order === 'descend' ? 'desc' : 'asc');
    }

    if (filters.level && filters.level.length > 0) {
      setQuery('levelfilter', filters.level.join(','));
      setFilteredValue(filters.level);
    }

    history.push(`${match.params.tableid}?${qs.stringify(queryObject)}`);
  };

  const onSearch = (value: string) => {
    if (value) {
      history.push(`${match.params.tableid}?page=1&search=${value}`);
    }
  };

  useEffect(() => {
    const onTableChange = async () => {
      const result = await postRequest('/api/submission', {
        ...query,
        table: match.params.tableid,
      });
      if (!result.success) {
        if (result.error) {
          message.error(formatMessage({ id: `error.${result.error}` }));
        } else message.error('Unknown Error.');
      } else {
        setTotal(result.total);
        setDataSource([...result.source]);
        if (query.levelfilter && typeof query.levelfilter === 'string') {
          setFilteredValue(query.levelfilter.split(','));
        } else setFilteredValue([]);
      }
      setLoading(false);
    };
    setTotal(0);
    setDataSource([]);
    setLoading(true);
    onTableChange();
    // eslint-disable-next-line
  }, [location.search, match.params.tableid]);

  if (!checkTable(match.params.tableid)) return <div />;

  return (
    <div>
      <Affix>
        <Menu mode="horizontal" defaultSelectedKeys={[match.params.tableid]}>
          <Item key="st">
            <Link to="st">STELLA</Link>
          </Item>
          <Item key="sl">
            <Link to="sl">SATELLITE</Link>
          </Item>
          <Item key="dpst">
            <Link to="dpst">DP STELLA</Link>
          </Item>
          <Item key="dp">
            <Link to="dp">DP SATELLITE</Link>
          </Item>
          <Item key="fr">
            <Link to="fr">EXOPLANET</Link>
          </Item>
        </Menu>
      </Affix>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Search
          placeholder={formatMessage({
            id: 'submissionPage.searchPlaceholder',
          })}
          defaultValue={typeof query.search === 'string' ? query.search : ''}
          onSearch={onSearch}
          enterButton
          loading={loading}
        />
        <Table<SongProps>
          bordered
          scroll={{ x: 720 }}
          dataSource={dataSource}
          loading={loading}
          pagination={{
            defaultCurrent: query.page ? Number(query.page) : 1,
            pageSize: 50,
            showSizeChanger: false,
            total,
          }}
          rowKey="_id"
          size="small"
          onChange={onChange}
          onHeaderRow={() => {
            return {
              style: { backgroundColor: 'white' },
            };
          }}
        >
          <Column<SongProps> key="entryNo" title="No" dataIndex="entryNo" sorter width={60} />
          <Column<SongProps>
            key="level"
            title="Lv"
            dataIndex="level"
            filters={levelArray[match.params.tableid].map((i) => {
              return {
                text: `${match.params.tableid}${i.toString()}`,
                value: i.toString(),
              };
            })}
            onFilter={(value, record) => value === record.level.toString()}
            defaultFilteredValue={(() => {
              if (typeof query.levelfilter === 'string') return query.levelfilter.split(',');
              if (!isNull(query.levelfilter) && typeof query.levelfilter === 'object')
                return [...query.levelfilter];
              return [];
            })()}
            filteredValue={filteredValue}
            width={60}
          />
          <Column<SongProps>
            ellipsis
            key="title"
            title={formatMessage({ id: 'submissionPage.th.0' })}
            dataIndex="title"
            render={(plaintext, record) => {
              return (
                <Link to={`../s/${match.params.tableid}/${record.entryNo}`}>{record.title}</Link>
              );
            }}
            width={240}
          />
          <Column<SongProps>
            key="proposer"
            title={formatMessage({ id: 'submissionPage.th.1' })}
            dataIndex="proposer"
            render={(plaintext, record) =>
              record.proposer.userlr2id || record.proposer.minirid ? (
                <Link
                  to={`/user/${match.params.tableid}/${
                    record.proposer.userlr2id || record.proposer.minirid
                  }`}
                >
                  {record.proposer.nickname}
                </Link>
              ) : (
                record.proposer.nickname
              )
            }
            width={100}
          />
          <Column<SongProps>
            key="vote"
            title={formatMessage({ id: 'submissionPage.th.2' })}
            render={(plaintext, record) =>
              record.voteResult !== 'new' ? (
                `${record.voteYes}/${record.voteNeutral}/${
                  record.voteNo
                }(${record.votePercent.toFixed(1)})`
              ) : (
                <span>
                  <LockOutlined />
                  &nbsp;VOTING...
                </span>
              )
            }
            width={100}
          />
          <Column<SongProps>
            key="voteResult"
            title={formatMessage({ id: 'submissionPage.th.3' })}
            dataIndex="voteResult"
            render={(plaintext, record) => {
              switch (record.voteResult) {
                case 'accept':
                  return (
                    <Tag color="green">{formatMessage({ id: 'submissionPage.status.accept' })}</Tag>
                  );
                case 'reject':
                  return (
                    <Tag color="red">{formatMessage({ id: 'submissionPage.status.reject' })}</Tag>
                  );
                case 'canceled':
                  return (
                    <Tag color="gray">
                      {formatMessage({ id: 'submissionPage.status.canceled' })}
                    </Tag>
                  );
                case 'new':
                  return (
                    <Tag color="orange">{formatMessage({ id: 'submissionPage.status.new' })}</Tag>
                  );
                default:
                  return null;
              }
            }}
            width={80}
          />

          <Column<SongProps>
            key="zureVerification"
            title={formatMessage({ id: 'votePage.th.6' })}
            dataIndex="zureVerification"
            align="center"
            render={(plaintext, record) => {
              if (!record.url_diff) {
                return (
                  <Popover
                    content={
                      <span>
                        {formatMessage({
                          id: 'votePage.isNeutralZureVerification',
                        })}
                      </span>
                    }
                  >
                    <InfoCircleTwoTone twoToneColor="#1890ff" style={{ fontSize: '20px' }} />
                  </Popover>
                );
              }
              if (record.zureVerification) {
                return (
                  <Popover
                    content={
                      <span>
                        {formatMessage({ id: 'votePage.isZureVerification' })}
                        <a href={irUrl(record.originMd5, 'lr2')}>Click</a>
                      </span>
                    }
                  >
                    <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '20px' }} />
                  </Popover>
                );
              }
              return (
                <Popover
                  content={<span>{formatMessage({ id: 'votePage.isNotZureVerification' })}</span>}
                >
                  <CloseCircleTwoTone twoToneColor="#cd5c5c" style={{ fontSize: '20px' }} />
                </Popover>
              );
            }}
            width={100}
          />
          <Column<SongProps>
            key="publishedTime"
            title={formatMessage({ id: 'submissionPage.th.4' })}
            dataIndex="publishedTime"
            render={(plaintext, record) => dayjs(record.publishedTime).format('YYYY/MM/DD')}
            width={100}
          />
        </Table>
      </Space>
    </div>
  );
};

export default Submission;
