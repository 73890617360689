const flattenObject = (ob: any, pf?: string) => {
  const toReturn: any = {};
  const prefix = pf ? `${pf}.` : '';

  Object.getOwnPropertyNames(ob).forEach((i) => {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      Object.assign(toReturn, flattenObject(ob[i], prefix + i));
    } else {
      toReturn[prefix + i] = ob[i];
    }
  });
  return toReturn;
};

export default flattenObject;
