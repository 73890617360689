import { defaultUserState } from '../store/atom';
import UserProps from './user';

export const defaultRevisionProps: RevisionProps = {
  entryNo: 0,
  subNo: 0,
  type: '',
  md5: '',
  sha256: '',
  title: '',
  artist: '',
  level: 0,
  proposer: defaultUserState,
  comment: '',
  publishedTime: new Date(0),
  voters: [],
  voters2: [],
  voteYes: 0,
  voteNeutral: 0,
  voteNo: 0,
  votePercent: 0,
  voteResult: '',
  voteVersion: 1,
};

export interface Voter {
  voter: UserProps;
  voterLevel: string;
  voterResult: string;
  voterComment: string;
}

export interface Voter2 {
  voter: UserProps;
  voterResult: string;
  voterComment: string;
}

interface RevisionProps {
  entryNo: number;
  subNo: number;
  type: string;
  md5: string;
  sha256: string;
  title: string;
  artist: string;
  level: number;
  changeTo?: number;
  proposer: UserProps;
  comment: string;
  publishedTime: Date;
  voters: Voter[];
  voters2: Voter[];
  voteYes: number;
  voteNeutral: number;
  voteNo: number;
  votePercent: number;
  voteResult: string;
  voteVersion: number;
}

export default RevisionProps;
