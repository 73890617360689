import React, { useState } from 'react';
import { Alert, Button, Collapse, Form, Input, Select, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import JSZip from 'jszip';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { RouteComponentProps, useHistory } from 'react-router';
import qs from 'querystring';
import UploaderTable from './table';
import { ChartProps } from '../../props/uploader';
import { userState } from '../../store/atom';
import { useRecoilValue } from 'recoil';
import { uploadRequest } from '../../utils/ajax';

const { Panel } = Collapse;
const { Item } = Form;
const { Search } = Input;
const { Option } = Select;
const { Dragger } = Upload;

const spRegex = /^#[0-9]{3}1[1-9]/;
const player1Regex = /^#[0-9]{3}1[8-9]/;
const dpRegex = /^#[0-9]{3}2[1-9]/;
const player2Regex = /^#[0-9]{3}2[8-9]/;
const lnRegex = /^#[0-9]{3}[5-6][1-9]/;
const subtitleRegex = /\[.*\]|-.*-|\(.*\)|~.*~|<.*>/;

const readNumOfKeys = (lines: string[], keytype: number) => {
  let totalnotes = 0;
  let lnCount = 0;
  let lanedata;
  const hasLN = lines.find((i) => i.toUpperCase().indexOf('#LNOBJ') >= 0);
  const lnObj = hasLN ? hasLN.slice(7, 9) : '';
  if (keytype === 5 || keytype === 7) {
    lanedata = lines.filter((i) => spRegex.test(i) || lnRegex.test(i));
  } else if (keytype === 10 || keytype === 14) {
    lanedata = lines.filter((i) => spRegex.test(i) || dpRegex.test(i) || lnRegex.test(i));
  }
  if (lanedata) {
    lanedata.forEach((slice) => {
      const lane = slice.slice(4, 5);
      const i = slice.slice(7);
      const partsLength = i.length % 2 === 1 ? (i.length - 1) / 2 : i.length / 2;
      let part: string;
      if (lane === '1' || lane === '2') {
        for (let x = 0; x < partsLength; x += 1) {
          part = `${i[x * 2]}${i[x * 2 + 1]}`;
          if (part && part !== '00' && part !== lnObj) {
            totalnotes += 1;
          }
        }
      } else if (lane === '5' || lane === '6') {
        for (let x = 0; x < partsLength; x += 1) {
          part = `${i[x * 2]}${i[x * 2 + 1]}`;
          if (part && part !== '00' && part !== lnObj) {
            lnCount += 1;
          }
        }
      }
    });
  }
  return totalnotes + lnCount / 2;
};

const Uploader: React.FC<RouteComponentProps> = ({ location }) => {
  const [form] = Form.useForm();
  const [submitDisable, setSubmitDisable] = useState(false);
  const [submitRefresh, setSubmitRefresh] = useState(false);
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const [fileInfo, setFileInfo] = useState<ChartProps[]>([]);
  const [keysSearch, setKeysSearch] = useState<string[]>([]);
  const [tagsSearch, setTagsSearch] = useState<string[]>([]);
  const history = useHistory();
  const user = useRecoilValue(userState);
  const { formatMessage } = useIntl();

  const query = qs.parse(location.search.substring(1));

  const onChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    function readKeysInfo(fileBuffers: ArrayBuffer[], bmsFilenames: string[]) {
      const keys: string[] = [];
      const bindFileInfo: ChartProps[] = [];
      const decoder = new TextDecoder('Shift_JIS');
      const fileStrings = fileBuffers.map((i) => decoder.decode(i));
      fileStrings.forEach((fileString, index) => {
        const lines = fileString.split('\n');
        let chartname = lines.find((i) => i.toUpperCase().indexOf('#SUBTITLE') >= 0);
        if (chartname) {
          chartname = chartname.slice(10);
        } else {
          chartname = lines.find((i) => i.toUpperCase().indexOf('#TITLE') >= 0);
          if (chartname) {
            const regArr = subtitleRegex.exec(chartname);
            if (regArr) chartname = regArr[regArr.length - 1];
            else chartname = '';
          } else chartname = '';
        }
        const BPM: number[] = [];
        const filteredBPM = lines.filter(
          (i) => /^#BPM/.test(i.toUpperCase()) || /^#[0-9]{3}03/.test(i.toUpperCase()),
        );
        filteredBPM.forEach((i) => {
          if (/^#BPM /.test(i.toUpperCase())) {
            BPM.push(Number(i.slice(5)));
          } else if (/^#BPM[0-9]{2} /.test(i.toUpperCase())) {
            BPM.push(Number(i.slice(7)));
          } else if (/^#[0-9]{3}03:/.test(i.toUpperCase())) {
            const parts = i.slice(7);
            const partsLength = parts.length % 2 === 1 ? (parts.length - 1) / 2 : parts.length / 2;
            let part: string;
            for (let x = 0; x < partsLength; x += 1) {
              part = `${parts[x * 2]}${parts[x * 2 + 1]}`;
              if (part && part !== '00') BPM.push(parseInt(part, 16));
            }
          }
        });
        const minbpm = Math.min(...BPM);
        const maxbpm = Math.max(...BPM);
        let difficulty: any = lines.find((i) => i.toUpperCase().indexOf('#DIFFICULTY') >= 0);
        if (difficulty) {
          difficulty = Number(difficulty.slice(12));
        } else difficulty = 0;
        let level: any = lines.find((i) => i.toUpperCase().indexOf('#PLAYLEVEL') >= 0);
        if (level) {
          level = level.slice(11);
        } else level = '0';
        let judgeText: string;
        let judge: any = lines.find((i) => i.toUpperCase().indexOf('#RANK') >= 0);
        if (judge) {
          judge = Number(judge.slice(6));
          if (judge === 0) judgeText = 'VERYHARD';
          else if (judge === 1) judgeText = 'HARD';
          else if (judge === 2) judgeText = 'NORMAL';
          else if (judge === 3) judgeText = 'EASY';
          else judgeText = 'NORMAL';
        } else {
          judge = 2;
          judgeText = 'NORMAL';
        }
        let total: any = lines.find((i) => i.toUpperCase().indexOf('#TOTAL') >= 0);
        if (total) {
          total = Number(total.slice(7));
        } else total = 0;
        let notes: any;
        if (bmsFilenames[index].indexOf('.pms') >= 0) keys.push('9keys');
        else {
          const spKeys = lines.filter((i) => spRegex.test(i.toUpperCase()));
          const sevenKeys = lines.filter((i) => player1Regex.test(i.toUpperCase()));
          const dpKeys = lines.filter((i) => dpRegex.test(i.toUpperCase()));
          const fourteenKeys = lines.filter((i) => player2Regex.test(i.toUpperCase()));
          if (dpKeys.length <= 0) {
            if (sevenKeys.length > 0) {
              keys.push('7keys');
              notes = readNumOfKeys(lines, 7);
            } else if (spKeys.length > 0) {
              keys.push('5keys');
              notes = readNumOfKeys(lines, 5);
            }
          } else if (sevenKeys.length > 0 || fourteenKeys.length > 0) {
            keys.push('14keys');
            notes = readNumOfKeys(lines, 14);
          } else if (spKeys.length > 0) {
            keys.push('10keys');
            notes = readNumOfKeys(lines, 10);
          }
        }
        bindFileInfo.push({
          chartname,
          md5: '',
          sha256: '',
          level,
          difficulty,
          judge,
          judgeText,
          notes,
          minbpm,
          maxbpm,
          total,
          mark: 'sl',
          filename: bmsFilenames[index],
        });
      });
      setFileInfo(bindFileInfo);
      form.setFieldsValue({
        keys: keys.filter((i, index) => keys.indexOf(i) === index),
        fileinfo: [...bindFileInfo],
      });
    }

    function readFileInfo(fileBuffer: ArrayBuffer) {
      const decoder = new TextDecoder('Shift_JIS');
      const fileString = decoder.decode(fileBuffer);
      const lines = fileString.split('\n');
      const title = lines.find((i) => i.toUpperCase().indexOf('#TITLE') >= 0);
      const artist = lines.find((i) => i.toUpperCase().indexOf('#ARTIST') >= 0);
      if (title) {
        const subtitleIndex = subtitleRegex.exec(title);
        if (subtitleIndex) {
          form.setFieldsValue({ title: title.slice(7, subtitleIndex.index) });
        } else {
          form.setFieldsValue({ title: title.slice(7) });
        }
      }
      if (artist) {
        form.setFieldsValue({ artist: artist.slice(8) });
      }
    }

    setFileInfo([]);
    const zip = new JSZip();
    const { file } = info;
    if (file.size / 1024 / 1024 > 5) {
      message.error(formatMessage({ id: 'error.ELIMITSIZE' }));
    } else if (file.status === 'removed') {
      form.resetFields();
    } else if (file.name.indexOf('.zip') >= 0) {
      try {
        const loadZip = await zip.loadAsync(file as unknown as Blob);
        const bmsFilenames = Object.keys(loadZip.files).filter(
          (i) =>
            i.indexOf('.bme') >= 0 ||
            i.indexOf('.bml') >= 0 ||
            i.indexOf('.bms') >= 0 ||
            i.indexOf('.pms') >= 0,
        );
        if (bmsFilenames.length > 5) {
          message.error(formatMessage({ id: 'error.ELIMITSIZE2' }));
        } else if (bmsFilenames.length > 0) {
          setFileList([file]);
          const firstFileBinary = await (loadZip.file(bmsFilenames[0]) as JSZip.JSZipObject).async(
            'uint8array',
          );
          const fileBinaries = await Promise.all(
            bmsFilenames.map((i) => (loadZip.file(i) as JSZip.JSZipObject).async('uint8array')),
          );
          form.setFieldsValue({ nobms: fileBinaries.length });
          readFileInfo(firstFileBinary);
          readKeysInfo(fileBinaries, bmsFilenames);
        } else message.error(formatMessage({ id: 'error.ENOBMSINZIP' }));
      } catch {
        form.resetFields();
        setFileList([]);
        message.error(formatMessage({ id: 'error.ENOTVALIDFILE' }));
      }
    } else {
      setFileList([file]);
      const fileBinary = await new Response(file as unknown as Blob).arrayBuffer();
      const decoder = new TextDecoder('Shift_JIS');
      const fileString = decoder.decode(fileBinary);
      const lines = fileString.split('\n');
      if (!lines.find((i) => i.toUpperCase().indexOf('#TITLE') >= 0)) {
        form.resetFields();
        setFileList([]);
        message.error(formatMessage({ id: 'error.ENOTVALIDFILE' }));
      } else {
        form.setFieldsValue({ nobms: 1 });
        readFileInfo(fileBinary);
        readKeysInfo([fileBinary], [file.name]);
      }
    }
  };

  const onFinish = async (values: any) => {
    setSubmitDisable(true);
    const formData = new FormData();
    Object.keys(values).forEach((i) => {
      if (i === 'file') formData.append(i, values[i].file);
      else if (i === 'fileinfo') formData.append(i, JSON.stringify(values[i]));
      else if (values[i]) {
        formData.append(i, values[i]);
      }
    });
    const result = await uploadRequest('/api/uploader/submit', formData);
    if (!result.success) {
      if (result.error) {
        message.error(formatMessage({ id: `error.${result.error}` }));
      } else message.error('Unknown Error.');
      setSubmitDisable(false);
    } else {
      message.success('OK');
      form.resetFields();
      setFileList([]);
      setFileInfo([]);
      setSubmitRefresh(true);
      setSubmitDisable(false);
    }
  };

  const onSearch = (value: string) => {
    let searchQuery = `?page=1&search=${value || ''}`;
    if (tagsSearch.length > 0) searchQuery += `&tags=${tagsSearch.join(',')}`;
    if (keysSearch.length > 0) searchQuery += `&keys=${keysSearch.join(',')}`;
    setSubmitRefresh(true);
    history.push(searchQuery);
  };

  const onTagsSearch = (value: string[]) => {
    setTagsSearch(value);
  };

  const onKeysSearch = (value: string[]) => {
    setKeysSearch(value);
  };

  const onRemove = () => {
    form.resetFields();
    setFileList([]);
  };

  const tableCallback = () => {
    setSubmitRefresh(false);
  };

  return (
    <div>
      <div className="normal-container">
        <Alert type="warning" message={formatMessage({ id: 'uploaderPage.warning' })} />
      </div>
      <div className="normal-container">
        <div className="framed text-center">
          <p>
            {formatMessage({
              id: 'uploaderPage.uploadTableDescription',
            })}
          </p>
          <h2>
            <a href="/upload.html">
              {formatMessage({
                id: 'uploaderPage.uploadTable',
              })}
            </a>
          </h2>
        </div>
        <div className="framed">
          <Collapse defaultActiveKey={['1']}>
            <Panel key="1" header={formatMessage({ id: 'uploaderPage.panelHeader' })}>
              <Form
                form={form}
                size="small"
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 4 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 20 },
                }}
                onFinish={onFinish}
              >
                <Item
                  name="file"
                  labelCol={{ span: 0 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'uploaderPage.required.file',
                      }),
                    },
                  ]}
                >
                  <Dragger
                    accept=".zip, .bms, .bme, .bml, .pms"
                    beforeUpload={() => false}
                    fileList={fileList}
                    onChange={onChange}
                    onRemove={onRemove}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      {formatMessage({ id: 'uploaderPage.clickOrDrag' })}
                    </p>
                    <p className="ant-upload-hint">
                      {formatMessage({ id: 'uploaderPage.uploadHint' })}
                    </p>
                  </Dragger>
                </Item>
                {user.userid ? null : (
                  <>
                    <Item
                      label={formatMessage({
                        id: 'uploaderPage.formLabel.proposer',
                      })}
                      name="proposer"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({
                            id: 'uploaderPage.required.proposer',
                          }),
                        },
                        {
                          max: 12,
                          message: formatMessage({
                            id: 'uploaderPage.max.proposer',
                          }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={formatMessage({
                          id: 'uploaderPage.placeholder.proposer',
                        })}
                      />
                    </Item>
                    <Item
                      label={formatMessage({
                        id: 'uploaderPage.formLabel.password',
                      })}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({
                            id: 'uploaderPage.required.password',
                          }),
                        },
                        {
                          min: 6,
                          max: 18,
                          message: formatMessage({
                            id: 'uploaderPage.required.password',
                          }),
                        },
                      ]}
                    >
                      <Input
                        type="password"
                        placeholder={formatMessage({
                          id: 'uploaderPage.placeholder.password',
                        })}
                      />
                    </Item>
                  </>
                )}
                <Item
                  label={formatMessage({
                    id: 'uploaderPage.formLabel.title',
                  })}
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'uploaderPage.required.title',
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={formatMessage({
                      id: 'uploaderPage.placeholder.title',
                    })}
                  />
                </Item>
                <Item
                  label={formatMessage({
                    id: 'uploaderPage.formLabel.artist',
                  })}
                  name="artist"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'uploaderPage.required.artist',
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={formatMessage({
                      id: 'uploaderPage.placeholder.artist',
                    })}
                  />
                </Item>
                <Item
                  label={formatMessage({
                    id: 'uploaderPage.formLabel.tags',
                  })}
                  name="tags"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'uploaderPage.required.tags',
                      }),
                    },
                    {
                      max: 8,
                      type: 'array',
                      message: formatMessage({ id: 'uploaderPage.max.tags' }),
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder={formatMessage({
                      id: 'uploaderPage.placeholder.tags',
                    })}
                  >
                    <Option value="stream">
                      {formatMessage({ id: 'uploaderPage.tags.stream' })}
                    </Option>
                    <Option value="jacks">
                      {formatMessage({ id: 'uploaderPage.tags.jacks' })}
                    </Option>
                    <Option value="gachi">
                      {formatMessage({ id: 'uploaderPage.tags.gachi' })}
                    </Option>
                    <Option value="delay">
                      {formatMessage({ id: 'uploaderPage.tags.delay' })}
                    </Option>
                    <Option value="chords">
                      {formatMessage({ id: 'uploaderPage.tags.chords' })}
                    </Option>
                    <Option value="stairs">
                      {formatMessage({ id: 'uploaderPage.tags.stairs' })}
                    </Option>
                    <Option value="doublestairs">
                      {formatMessage({
                        id: 'uploaderPage.tags.doublestairs',
                      })}
                    </Option>
                    <Option value="burst">
                      {formatMessage({ id: 'uploaderPage.tags.burst' })}
                    </Option>
                    <Option value="lowtotal">
                      {formatMessage({ id: 'uploaderPage.tags.lowtotal' })}
                    </Option>
                    <Option value="hightotal">
                      {formatMessage({ id: 'uploaderPage.tags.hightotal' })}
                    </Option>
                    <Option value="trills">
                      {formatMessage({ id: 'uploaderPage.tags.trills' })}
                    </Option>
                    <Option value="scratch">
                      {formatMessage({ id: 'uploaderPage.tags.scratch' })}
                    </Option>
                    <Option value="zure">{formatMessage({ id: 'uploaderPage.tags.zure' })}</Option>
                    <Option value="gimmick">
                      {formatMessage({ id: 'uploaderPage.tags.gimmick' })}
                    </Option>
                    <Option value="soflan">
                      {formatMessage({ id: 'uploaderPage.tags.soflan' })}
                    </Option>
                    <Option value="anchors">
                      {formatMessage({ id: 'uploaderPage.tags.anchors' })}
                    </Option>
                    <Option value="random">
                      {formatMessage({ id: 'uploaderPage.tags.random' })}
                    </Option>
                    <Option value="ln">{formatMessage({ id: 'uploaderPage.tags.ln' })}</Option>
                    <Option value="firstkill">
                      {formatMessage({ id: 'uploaderPage.tags.firstkill' })}
                    </Option>
                    <Option value="middlekill">
                      {formatMessage({ id: 'uploaderPage.tags.middlekill' })}
                    </Option>
                    <Option value="lastkill">
                      {formatMessage({ id: 'uploaderPage.tags.lastkill' })}
                    </Option>
                    <Option value="zero">{formatMessage({ id: 'uploaderPage.tags.zero' })}</Option>
                  </Select>
                </Item>
                <Item
                  label={formatMessage({
                    id: 'uploaderPage.formLabel.keys',
                  })}
                  name="keys"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'uploaderPage.required.keys',
                      }),
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder={formatMessage({
                      id: 'uploaderPage.placeholder.keys',
                    })}
                  >
                    <Option value="5keys">5K</Option>
                    <Option value="7keys">7K</Option>
                    <Option value="9keys">9K</Option>
                    <Option value="10keys">10K</Option>
                    <Option value="14keys">14K</Option>
                  </Select>
                </Item>
                <Item
                  label={formatMessage({
                    id: 'uploaderPage.formLabel.event',
                  })}
                  name="event"
                >
                  <Input
                    placeholder={formatMessage({
                      id: 'uploaderPage.placeholder.event',
                    })}
                  />
                </Item>
                <Item
                  label={formatMessage({
                    id: 'uploaderPage.formLabel.songUrl',
                  })}
                  name="songUrl"
                >
                  <Input
                    placeholder={formatMessage({
                      id: 'uploaderPage.placeholder.songUrl',
                    })}
                  />
                </Item>
                <Item label="Youtube" name="youtube">
                  <span>
                    https://youtube.com/watch?v=
                    <Input style={{ display: 'inline', width: 120 }} />
                  </span>
                </Item>
                <Item
                  label={formatMessage({
                    id: 'uploaderPage.formLabel.nobms',
                  })}
                  name="nobms"
                >
                  <Input disabled />
                </Item>
                <Item
                  label={formatMessage({
                    id: 'uploaderPage.formLabel.list',
                  })}
                >
                  {fileInfo.map((i, index) => (
                    <span key={index.toString()}>
                      <span>{i.filename}</span>
                      <Item name={['fileinfo', index, 'filename']} noStyle>
                        <Input type="hidden" disabled defaultValue={i.filename} />
                      </Item>
                      <Item name={['fileinfo', index, 'judge']} noStyle>
                        <Input type="hidden" disabled defaultValue={i.judge} />
                      </Item>
                      <Item name={['fileinfo', index, 'minbpm']} noStyle>
                        <Input type="hidden" disabled defaultValue={i.minbpm} />
                      </Item>
                      <Item name={['fileinfo', index, 'maxbpm']} noStyle>
                        <Input type="hidden" disabled defaultValue={i.maxbpm} />
                      </Item>
                      <Item name={['fileinfo', index, 'total']} noStyle>
                        <Input type="hidden" disabled defaultValue={i.total} />
                      </Item>
                      <Item name={['fileinfo', index, 'difficulty']} noStyle>
                        <Input type="hidden" disabled defaultValue={i.difficulty} />
                      </Item>
                      <Input.Group compact>
                        <Item
                          name={['fileinfo', index, 'chartname']}
                          rules={[
                            {
                              max: 50,
                              message: `${index + 1}: ${formatMessage({
                                id: 'uploaderPage.max.chartname',
                              })}`,
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder={formatMessage({
                              id: 'uploaderPage.placeholder.chartname',
                            })}
                            style={{ width: '40%', display: 'inline' }}
                            defaultValue={i.chartname}
                          />
                        </Item>
                        <Item
                          name={['fileinfo', index, 'mark']}
                          rules={[
                            {
                              required: true,
                              message: `${index + 1}: ${formatMessage({
                                id: 'uploaderPage.required.levelmark',
                              })}`,
                            },
                            {
                              max: 8,
                              message: `${index + 1}: ${formatMessage({
                                id: 'uploaderPage.max.levelmark',
                              })}`,
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder={formatMessage({
                              id: 'uploaderPage.placeholder.levelmark',
                            })}
                            style={{ width: '20%', display: 'inline' }}
                            defaultValue={i.level}
                          />
                        </Item>
                        <Item
                          name={['fileinfo', index, 'level']}
                          rules={[
                            {
                              required: true,
                              message: `${index + 1}: ${formatMessage({
                                id: 'uploaderPage.required.level',
                              })}`,
                            },
                            {
                              max: 4,
                              message: `${index + 1}: ${formatMessage({
                                id: 'uploaderPage.max.level',
                              })}`,
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder={formatMessage({
                              id: 'uploaderPage.placeholder.level',
                            })}
                            style={{ width: '10%', display: 'inline' }}
                            defaultValue={i.level}
                          />
                        </Item>
                        <Item name={['fileinfo', index, 'judgeText']} noStyle>
                          <Input
                            disabled
                            style={{ width: '15%', display: 'inline' }}
                            defaultValue={i.judgeText}
                          />
                        </Item>
                        <Item name={['fileinfo', index, 'notes']} noStyle>
                          <Input
                            disabled
                            style={{ width: '15%', display: 'inline' }}
                            defaultValue={i.notes}
                          />
                        </Item>
                      </Input.Group>
                    </span>
                  ))}
                </Item>
                <Item
                  label={formatMessage({
                    id: 'uploaderPage.formLabel.comment',
                  })}
                  rules={[
                    {
                      max: 50,
                      message: formatMessage({
                        id: 'uploaderPage.max.comment',
                      }),
                    },
                  ]}
                  name="comment"
                >
                  <Input
                    placeholder={formatMessage({
                      id: 'uploaderPage.placeholder.comment',
                    })}
                  />
                </Item>
                <Item
                  wrapperCol={{
                    xs: {
                      span: 24,
                      offset: 0,
                    },
                    sm: {
                      span: 13,
                      offset: 11,
                    },
                  }}
                >
                  <Button type="primary" htmlType="submit" size="middle" disabled={submitDisable}>
                    {formatMessage({ id: 'uploaderPage.submitButton' })}
                  </Button>
                </Item>
              </Form>
            </Panel>
          </Collapse>
        </div>
      </div>
      <div className="normal-container">
        <Search
          placeholder={formatMessage({
            id: 'uploaderPage.searchPlaceholder',
          })}
          defaultValue={typeof query.search === 'string' ? query.search : ''}
          onSearch={onSearch}
          enterButton
        />
        <Select
          mode="multiple"
          onChange={onTagsSearch}
          style={{ width: '50%' }}
          placeholder={formatMessage({
            id: 'uploaderPage.placeholder.tags',
          })}
        >
          <Option value="stream">{formatMessage({ id: 'uploaderPage.tags.stream' })}</Option>
          <Option value="jacks">{formatMessage({ id: 'uploaderPage.tags.jacks' })}</Option>
          <Option value="gachi">{formatMessage({ id: 'uploaderPage.tags.gachi' })}</Option>
          <Option value="delay">{formatMessage({ id: 'uploaderPage.tags.delay' })}</Option>
          <Option value="chords">{formatMessage({ id: 'uploaderPage.tags.chords' })}</Option>
          <Option value="stairs">{formatMessage({ id: 'uploaderPage.tags.stairs' })}</Option>
          <Option value="doublestairs">
            {formatMessage({
              id: 'uploaderPage.tags.doublestairs',
            })}
          </Option>
          <Option value="burst">{formatMessage({ id: 'uploaderPage.tags.burst' })}</Option>
          <Option value="lowtotal">{formatMessage({ id: 'uploaderPage.tags.lowtotal' })}</Option>
          <Option value="hightotal">{formatMessage({ id: 'uploaderPage.tags.hightotal' })}</Option>
          <Option value="trills">{formatMessage({ id: 'uploaderPage.tags.trills' })}</Option>
          <Option value="scratch">{formatMessage({ id: 'uploaderPage.tags.scratch' })}</Option>
          <Option value="zure">{formatMessage({ id: 'uploaderPage.tags.zure' })}</Option>
          <Option value="gimmick">{formatMessage({ id: 'uploaderPage.tags.gimmick' })}</Option>
          <Option value="soflan">{formatMessage({ id: 'uploaderPage.tags.soflan' })}</Option>
          <Option value="anchors">{formatMessage({ id: 'uploaderPage.tags.anchors' })}</Option>
          <Option value="random">{formatMessage({ id: 'uploaderPage.tags.random' })}</Option>
          <Option value="ln">{formatMessage({ id: 'uploaderPage.tags.ln' })}</Option>
          <Option value="firstkill">{formatMessage({ id: 'uploaderPage.tags.firstkill' })}</Option>
          <Option value="middlekill">
            {formatMessage({ id: 'uploaderPage.tags.middlekill' })}
          </Option>
          <Option value="lastkill">{formatMessage({ id: 'uploaderPage.tags.lastkill' })}</Option>
          <Option value="zero">{formatMessage({ id: 'uploaderPage.tags.zero' })}</Option>
        </Select>
        <Select
          mode="multiple"
          onChange={onKeysSearch}
          style={{ width: 'calc(50% - 46px)' }}
          placeholder={formatMessage({
            id: 'uploaderPage.placeholder.keys',
          })}
        >
          <Option value="5keys">5K</Option>
          <Option value="7keys">7K</Option>
          <Option value="9keys">9K</Option>
          <Option value="10keys">10K</Option>
          <Option value="14keys">14K</Option>
        </Select>
      </div>
      <UploaderTable submitRefresh={submitRefresh} callback={tableCallback} tagsSearch={tagsSearch} keysSearch={keysSearch} />
    </div>
  );
};

export default Uploader;
