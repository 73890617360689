/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Alert, Button, Form, Input, message } from 'antd';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { userState } from '../../store/atom';
import { postRequest } from '../../utils/ajax';

const { Item } = Form;

type ValidateStatusType = '' | 'error' | 'success' | 'warning' | 'validating';

interface ValidateStatusProps {
  userid: ValidateStatusType;
  userpw: ValidateStatusType;
  userpwAgain: ValidateStatusType;
  minirid: ValidateStatusType;
  nickname: ValidateStatusType;
}

interface ErrorFieldsProps {
  userid?: string;
  userpw?: string;
  userpwAgain?: string;
  minirid?: string;
  nickname?: string;
}

const defaultValidateStatusProps: ValidateStatusProps = {
  userid: '',
  userpw: '',
  userpwAgain: '',
  minirid: '',
  nickname: '',
};

const minirRegex = /^([0-9a-f]{8})-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{12})$/;

const SignUpBeatoraja: React.FC = () => {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(false);
  const [validateStatus, setValidateStatus] = useState<ValidateStatusProps>(
    defaultValidateStatusProps,
  );
  const [errorFields, setErrorFields] = useState<ErrorFieldsProps>({});

  const user = useRecoilValue(userState);
  const { formatMessage } = useIntl();

  const onCheck = async (type: string) => {
    const values = form.getFieldValue(type);
    switch (type) {
      case 'userid':
        setValidateStatus({ ...validateStatus, userid: 'validating' });
        setErrorFields({ ...errorFields, userid: '' });
        if (values && /^[a-zA-Z0-9-_]{6,18}$/.test(values)) {
          const result = await postRequest('/api/signup/check', {
            userid: values,
          });
          if (!result.success) {
            setValidateStatus({ ...validateStatus, userid: 'error' });
            setErrorFields({
              ...errorFields,
              userid: formatMessage({ id: `error.${result.error}` }),
            });
          } else {
            setValidateStatus({ ...validateStatus, userid: 'success' });
          }
        } else if (values && (values.length < 6 || values.length > 18)) {
          setValidateStatus({ ...validateStatus, userid: 'error' });
          setErrorFields({
            ...errorFields,
            userid: formatMessage({ id: 'error.NOTVALIDID' }),
          });
        } else {
          setValidateStatus({ ...validateStatus, userid: 'error' });
          setErrorFields({
            ...errorFields,
            userid: formatMessage({ id: 'error.NOTVALIDID2' }),
          });
        }
        break;
      case 'userpw':
        setValidateStatus({ ...validateStatus, userpw: 'validating' });
        setErrorFields({ ...errorFields, userpw: '' });
        if (values && /^[a-zA-Z0-9-_]{8,24}$/.test(values)) {
          setValidateStatus({ ...validateStatus, userpw: 'success' });
        } else if (values && (values.length < 8 || values.length > 24)) {
          setValidateStatus({ ...validateStatus, userpw: 'error' });
          setErrorFields({
            ...errorFields,
            userpw: formatMessage({ id: 'error.NOTVALIDPW' }),
          });
        } else {
          setValidateStatus({ ...validateStatus, userpw: 'error' });
          setErrorFields({
            ...errorFields,
            userpw: formatMessage({ id: 'error.NOTVALIDPW2' }),
          });
        }
        break;
      case 'userpwAgain':
        setValidateStatus({ ...validateStatus, userpwAgain: 'validating' });
        setErrorFields({ ...errorFields, userpwAgain: '' });
        if (
          values &&
          /^[a-zA-Z0-9-_]{8,24}$/.test(values) &&
          form.getFieldValue('userpw') === values
        ) {
          setValidateStatus({ ...validateStatus, userpwAgain: 'success' });
        } else {
          setValidateStatus({ ...validateStatus, userpwAgain: 'error' });
          setErrorFields({
            ...errorFields,
            userpwAgain: formatMessage({ id: 'error.NOTEQUALPW' }),
          });
        }
        break;
      case 'minirid':
        setValidateStatus({ ...validateStatus, minirid: 'validating' });
        setErrorFields({ ...errorFields, minirid: '' });
        if (values && minirRegex.test(values)) {
          const result = await postRequest('/api/signup/check', {
            minirid: values,
          });
          if (!result.success) {
            setValidateStatus({ ...validateStatus, minirid: 'error' });
            setErrorFields({
              ...errorFields,
              minirid: formatMessage({ id: `error.${result.error}` }),
            });
          } else {
            setValidateStatus({ ...validateStatus, minirid: 'success' });
          }
        } else {
          setValidateStatus({ ...validateStatus, minirid: 'error' });
          setErrorFields({
            ...errorFields,
            minirid: formatMessage({ id: 'error.NOTVALIDMINIRID' }),
          });
        }
        break;
      case 'nickname':
        setValidateStatus({ ...validateStatus, nickname: 'validating' });
        setErrorFields({ ...errorFields, nickname: '' });
        if (values && /^\S{2,12}$/.test(values)) {
          const result = await postRequest('/api/signup/check', {
            nickname: values,
          });
          if (!result.success) {
            setValidateStatus({ ...validateStatus, nickname: 'error' });
            setErrorFields({
              ...errorFields,
              nickname: formatMessage({ id: `error.${result.error}` }),
            });
          } else {
            setValidateStatus({ ...validateStatus, nickname: 'success' });
          }
        } else if (values && (values.length < 2 || values.length > 12)) {
          setValidateStatus({ ...validateStatus, nickname: 'error' });
          setErrorFields({
            ...errorFields,
            nickname: formatMessage({ id: 'error.NOTVALIDNICK' }),
          });
        } else {
          setValidateStatus({ ...validateStatus, nickname: 'error' });
          setErrorFields({
            ...errorFields,
            nickname: formatMessage({ id: 'error.NOTVALIDNICK2' }),
          });
        }
        break;
      default:
        break;
    }
  };

  const onFinish = async (values: any) => {
    const validateFields: Array<keyof ValidateStatusProps> = [
      'userid',
      'userpw',
      'userpwAgain',
      'minirid',
      'nickname',
    ];
    if (
      validateFields.every((i) => validateStatus[i] === 'success' && values[i]) &&
      values.userpw === values.userpwAgain
    ) {
      setDisable(true);
      const result = await postRequest('/api/signup/submit/beatoraja', values);
      if (!result.success) {
        message.error(result.error);
        setDisable(false);
      } else {
        window.location.href = `https://www.gaftalk.com/minir/#/oauth/Stella/${result.secretKey}${result.ciphertext}`;
      }
    } else {
      message.error(formatMessage({ id: 'error.NOTINFO' }));
    }
  };

  const onFinishFailed = (err: any) => {
    const errorObject: any = {};
    if (err.userid) errorObject.userid = 'error';
    if (err.userpw) errorObject.userpw = 'error';
    if (err.userpwAgain) errorObject.userpwAgain = 'error';
    if (err.nickname) errorObject.nickname = 'error';
    if (err.minirid) errorObject.minirid = 'error';
    setValidateStatus({ ...validateStatus, ...errorObject });
  };

  if (user.userid) return <div />;

  return (
    <div className="normal-container">
      <Alert type="warning" message={formatMessage({ id: 'signupPage.warning' })} />
      <Form
        form={form}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 8 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Item
          label={formatMessage({ id: 'signupPage.placeholders.userid' })}
          name="userid"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'signupPage.emptyError.userid',
              }),
            },
          ]}
          hasFeedback
          validateStatus={validateStatus.userid}
          help={errorFields.userid}
        >
          <Input
            autoComplete="off"
            placeholder={formatMessage({
              id: 'signupPage.placeholders.userid',
            })}
            onBlur={() => onCheck('userid')}
          />
        </Item>
        <Item
          label={formatMessage({ id: 'signupPage.placeholders.userpw' })}
          name="userpw"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'signupPage.emptyError.userpw',
              }),
            },
          ]}
          hasFeedback
          validateStatus={validateStatus.userpw}
          help={errorFields.userpw}
        >
          <Input
            placeholder={formatMessage({
              id: 'signupPage.placeholders.userpw',
            })}
            type="password"
            onBlur={() => onCheck('userpw')}
          />
        </Item>
        <Item
          label={formatMessage({ id: 'signupPage.placeholders.userpwAgain' })}
          name="userpwAgain"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'signupPage.emptyError.userpwAgain',
              }),
            },
          ]}
          hasFeedback
          validateStatus={validateStatus.userpwAgain}
          help={errorFields.userpwAgain}
        >
          <Input
            placeholder={formatMessage({
              id: 'signupPage.placeholders.userpwAgain',
            })}
            type="password"
            onBlur={() => onCheck('userpwAgain')}
          />
        </Item>
        <Item
          label={formatMessage({ id: 'signupPage.placeholders.nickname' })}
          name="nickname"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'signupPage.emptyError.nickname',
              }),
            },
          ]}
          hasFeedback
          validateStatus={validateStatus.nickname}
          help={errorFields.nickname}
        >
          <Input
            autoComplete="off"
            placeholder={formatMessage({
              id: 'signupPage.placeholders.nickname',
            })}
            onBlur={() => onCheck('nickname')}
          />
        </Item>
        <Item
          label={formatMessage({ id: 'signupPage.placeholders.minirid' })}
          name="minirid"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'signupPage.emptyError.minirid',
              }),
            },
          ]}
          hasFeedback
          validateStatus={validateStatus.minirid}
          help={errorFields.minirid}
        >
          <Input
            autoComplete="off"
            placeholder={formatMessage({
              id: 'signupPage.placeholders.minirid',
            })}
            onBlur={() => onCheck('minirid')}
          />
        </Item>
        <Item
          wrapperCol={{
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }}
        >
          <Button type="primary" htmlType="submit" disabled={disable}>
            {formatMessage({ id: 'signupPage.submitButton' })}
          </Button>
        </Item>
      </Form>
    </div>
  );
};

export default SignUpBeatoraja;
