/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Table, message } from 'antd';
import { EnterOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { isUndefined } from 'util';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import RevisionProps from '../../props/revision';
import { postRequest } from '../../utils/ajax';

interface MatchProps {
  table: string;
}

interface SongDataProps {
  entryNo: number;
  md5: string;
  sha256: string;
  title: string;
  artist: string;
  level: number;
  url: string;
  url_diff: string;
  voteResult: string;
}

const defaultSongDataProps: SongDataProps = {
  entryNo: 0,
  md5: '',
  sha256: '',
  title: '',
  artist: '',
  level: 1,
  url: '',
  url_diff: '',
  voteResult: '',
};

const { Item } = Form;
const { Option } = Select;
const { Column } = Table;

const RevisionSubmit: React.FC<MatchProps> = ({ table }) => {
  const [form] = Form.useForm();
  const [songData, setSongData] = useState<SongDataProps>(defaultSongDataProps);
  const [songHistory, setSongHistory] = useState<RevisionProps[]>([]);
  const [spinning, setSpinning] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [searchData, setSearchData] = useState<SongDataProps[]>([]);
  const [changeDisable, setChangeDisable] = useState(true);
  const history = useHistory();
  const { formatMessage } = useIntl();

  const info = (values: any) => {
    Modal.confirm({
      content: <span>{formatMessage({ id: 'votePage.modalRevisionTitle' })}</span>,
      async onOk() {
        setSubmitDisable(true);

        const result = await postRequest('/api/vote/revision-submit', {
          ...values,
          table,
        });

        if (!result.success) {
          if (result.error) message.error(formatMessage({ id: `error.${result.error}` }));
          else message.error('Unknown Error.');
          setSubmitDisable(false);
        } else {
          message.success('OK');
          history.go(0);
        }
      },
    });
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [form, table]);

  const onFindSubmission = async (value: string) => {
    if (!value) return [];
    const result = await postRequest('/api/vote/search-submission', {
      table,
      value,
    });
    return result.source;
  };

  const onTypeChange = (value: string) => {
    if (value === 'change') setChangeDisable(false);
    else {
      form.setFieldsValue({ changeTo: undefined });
      setChangeDisable(true);
    }
  };

  const onSearch = async (value: string) => {
    const result = await onFindSubmission(value);
    setSearchData(result);
  };

  const onChange = async (value: number) => {
    const result = searchData.find((i) => {
      return i.entryNo === value;
    });
    if (result) {
      setSongData(result);
      setSpinning(true);
      const result2 = await postRequest(`/api/revision/search/${result.entryNo}`, { table });
      if (!result2.success) {
        if (result2.error) message.error(formatMessage({ id: `error.${result2.error}` }));
        else message.error('Unknown Error.');
      } else {
        setSongHistory([...result2.source]);
        setSpinning(false);
      }
    }
  };

  const onFinish = (values: any) => {
    if (songData.md5) info(values);
  };

  return (
    <Form
      form={form}
      labelCol={{
        xs: { span: 24 },
        sm: { span: 8 },
      }}
      wrapperCol={{
        xs: { span: 24 },
        sm: { span: 16 },
      }}
      onFinish={onFinish}
    >
      <Item
        label={formatMessage({ id: 'votePage.revisionSubmitForm.type' })}
        name="type"
        rules={[
          {
            required: true,
            message: formatMessage({
              id: 'votePage.revisionSubmitForm.typeError',
            }),
          },
        ]}
      >
        <Select
          placeholder={formatMessage({
            id: 'votePage.revisionSubmitForm.typePlaceholder',
          })}
          onChange={onTypeChange}
        >
          <Option value="change">{formatMessage({ id: 'votePage.type.change' })}</Option>
          <Option value="delete">{formatMessage({ id: 'votePage.type.delete' })}</Option>
          <Option value="resurrection">
            {formatMessage({ id: 'votePage.type.resurrection' })}
          </Option>
          {(() => {
            switch (table) {
              case 'st':
              case 'dpst':
                return (
                  <Option value="transfer">
                    {formatMessage({ id: 'votePage.type.transfer.1' })}
                  </Option>
                );
              case 'sl':
              case 'dp':
                return (
                  <Option value="transfer">
                    {formatMessage({ id: 'votePage.type.transfer.0' })}
                  </Option>
                );
              default:
                return null;
            }
          })()}
        </Select>
      </Item>
      <Item
        label={formatMessage({
          id: 'votePage.revisionSubmitForm.submission',
        })}
        name="submission"
        rules={[
          {
            required: true,
            message: formatMessage({
              id: 'votePage.revisionSubmitForm.submissionError',
            }),
          },
        ]}
      >
        <Select
          showSearch
          placeholder={formatMessage({
            id: 'votePage.revisionSubmitForm.submissionPlaceholder',
          })}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={onSearch}
          onChange={onChange}
          notFoundContent="Not Found"
        >
          {searchData.map((i: SongDataProps) => {
            return (
              <Option key={i.entryNo} value={i.entryNo}>
                [{formatMessage({ id: `submissionPage.status.${i.voteResult}` })}][No.{i.entryNo}]
                {i.title}
              </Option>
            );
          })}
        </Select>
      </Item>
      <Item>
        <Button disabled={songData.entryNo === 0}>
          <Link to={`/s/${table}/${songData.entryNo}`}>Submission</Link>
        </Button>
      </Item>
      <Item
        label={formatMessage({
          id: 'votePage.revisionSubmitForm.changeTo',
        })}
        name="changeTo"
        rules={[
          {
            required: !changeDisable,
            message: formatMessage({
              id: 'votePage.revisionSubmitForm.changeToError',
            }),
          },
        ]}
      >
        <Select
          placeholder={formatMessage({
            id: 'votePage.revisionSubmitForm.changeToPlaceholder',
          })}
          disabled={changeDisable}
        >
          {songData.level > 0 ? (
            <Option value="low">
              {formatMessage({
                id: 'votePage.revisionSubmitForm.changeToLow',
              })}
            </Option>
          ) : null}
          {songData.level < 12 ? (
            <Option value="high">
              {formatMessage({
                id: 'votePage.revisionSubmitForm.changeToHigh',
              })}
            </Option>
          ) : null}
        </Select>
      </Item>
      <Item
        label={formatMessage({
          id: 'votePage.revisionSubmitForm.comment',
        })}
        name="comment"
        rules={[
          {
            required: true,
            message: formatMessage({
              id: 'votePage.revisionSubmitForm.commentError',
            }),
          },
        ]}
      >
        <Input
          placeholder={formatMessage({
            id: 'votePage.revisionSubmitForm.commentError',
          })}
        />
      </Item>
      <Item
        wrapperCol={{
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        }}
      >
        <Button type="primary" htmlType="submit" disabled={submitDisable}>
          {formatMessage({ id: 'votePage.submitButton' })}
        </Button>
      </Item>
      <Table<RevisionProps>
        dataSource={songHistory}
        size="small"
        loading={spinning}
        pagination={false}
        bordered
        rowKey="_id"
        title={() => formatMessage({ id: 'votePage.revisionHistoryNotice' })}
        onHeaderRow={() => {
          return {
            style: { backgroundColor: 'white' },
          };
        }}
      >
        <Column<RevisionProps>
          key="type"
          title={formatMessage({ id: 'votePage.thSubmit.0' })}
          dataIndex="type"
          render={(plaintext, record) => {
            if (record.type === 'transfer') {
              if (table === 'st' || table === 'dpst')
                return formatMessage({
                  id: `votePage.type.${record.type}.1`,
                });
              if (table === 'sl' || table === 'dp')
                return formatMessage({
                  id: `votePage.type.${record.type}.0`,
                });
            }
            return formatMessage({ id: `votePage.type.${record.type}` });
          }}
        />
        <Column<RevisionProps>
          key="level"
          title="Lv"
          dataIndex="level"
          render={(plaintext, record) =>
            isUndefined(record.changeTo) ? record.level : `${record.level}→${record.changeTo}`
          }
        />
        <Column<RevisionProps>
          key="submission"
          title="Sub"
          dataIndex="submission"
          render={(plaintext, record) => (
            <Link to={`../../u/${table}/${record.entryNo}`}>
              <Button size="small">
                <EnterOutlined />
              </Button>
            </Link>
          )}
        />
        <Column<RevisionProps>
          key="vote"
          title={formatMessage({ id: 'votePage.thSubmit.1' })}
          render={(plaintext, record) =>
            record.voteResult === 'new' ? (
              <span>
                <LockOutlined />
                &nbsp;VOTING...
              </span>
            ) : (
              `${record.voteYes}/${record.voteNeutral}/${
                record.voteNo
              }(${record.votePercent.toFixed(1)})`
            )
          }
        />
        <Column<RevisionProps>
          key="publishedTime"
          title={formatMessage({ id: 'votePage.thSubmit.2' })}
          dataIndex="publishedTime"
          render={(plaintext, record) => dayjs(record.publishedTime).format('YYYY/MM/DD')}
        />
      </Table>
      <table className="normal-table" style={{ width: '100%' }}>
        <tbody>
          <tr>
            <th style={{ width: '100px' }}>MD5</th>
            <td>{songData.md5}</td>
          </tr>
          <tr>
            <th style={{ width: '100px' }}>SHA256</th>
            <td>{songData.sha256}</td>
          </tr>
          <tr>
            <th style={{ width: '100px' }}>{formatMessage({ id: 'votePage.songData.0' })}</th>
            <td>
              {table}
              {songData.level} {songData.title}
            </td>
          </tr>
          <tr>
            <th style={{ width: '100px' }}>{formatMessage({ id: 'votePage.songData.1' })}</th>
            <td>{songData.artist}</td>
          </tr>
          <tr>
            <th style={{ width: '100px' }}>{formatMessage({ id: 'votePage.songData.2' })}</th>
            <td>{songData.url}</td>
          </tr>
          <tr>
            <th style={{ width: '100px' }}>{formatMessage({ id: 'votePage.songData.3' })}</th>
            <td>{songData.url_diff}</td>
          </tr>
        </tbody>
      </table>
    </Form>
  );
};

export default RevisionSubmit;
