import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import Spinning from '../components/Spinning';
import { postRequest } from '../utils/ajax';

const { Panel } = Collapse;

interface NoticeComponentProps {
  lang: string;
}

interface NoticeContentProps {
  title: string;
  description: string;
}

interface NoticeProps {
  en: NoticeContentProps;
  ja: NoticeContentProps;
  ko: NoticeContentProps;
}

const ViolateRules: React.FC<NoticeComponentProps> = ({ lang }) => {
  const [loading, setLoading] = useState(true);
  const [violationArray, setViolationArray] = useState<NoticeProps[]>([]);
  const [violationText, setViolationText] = useState<string[]>([]);

  useEffect(() => {
    const onLoad = async () => {
      const result = await postRequest('/violation');
      const result2 = await postRequest('/violation-text', null, {
        contentType: 'text',
        headers: {},
      });
      setViolationArray(result);
      setViolationText(result2.split('\n'));
      setLoading(false);
    };
    onLoad();
    setLoading(false);
  }, []);

  return loading ? (
    <Spinning />
  ) : (
    <>
      <div className="framed">
        <Collapse accordion>
          {violationArray.map((i: any, index) => (
            <Panel key={index.toString()} header={i[lang].message}>
              {i[lang].description}
            </Panel>
          ))}
        </Collapse>
      </div>
      <div className="framed">
        {violationText.map((i, index) => (
          <p key={index.toString()}>{i}</p>
        ))}
      </div>
    </>
  );
};

export default ViolateRules;
