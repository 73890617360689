import React from 'react';
import { Collapse } from 'antd';
import { useIntl } from 'react-intl';

const { Panel } = Collapse;

const Help: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="p-32-64">
      <div className="framed text-center">
        <h1>{formatMessage({ id: 'helpPage.introTitle' })}</h1>
        <p>{formatMessage({ id: 'helpPage.intro' })}</p>
      </div>
      <div className="framed">
        <h1 className="text-center">{formatMessage({ id: 'helpPage.listTable' })}</h1>
        <Collapse>
          {['st', 'sl', 'dpst', 'dp', 'fr'].map((i, index) => (
            <Panel
              key={index.toString()}
              header={formatMessage({ id: `helpPage.tables.${i}.title` })}
            >
              <div className="text-center">
                <h1>
                  <a
                    href={formatMessage({
                      id: `helpPage.tables.${i}.table.url`,
                    })}
                  >
                    {formatMessage({ id: `helpPage.tables.${i}.table.name` })}
                  </a>
                </h1>
                <p>
                  {formatMessage({
                    id: `helpPage.tables.${i}.table.description`,
                  })}
                </p>
                <h1>
                  <a
                    href={formatMessage({
                      id: `helpPage.tables.${i}.voting.url`,
                    })}
                  >
                    {formatMessage({
                      id: `helpPage.tables.${i}.voting.name`,
                    })}
                  </a>
                </h1>
                <p>
                  {formatMessage({
                    id: `helpPage.tables.${i}.voting.description`,
                  })}
                </p>
                <h1>
                  <a
                    href={formatMessage({
                      id: `helpPage.tables.${i}.sub.url`,
                    })}
                  >
                    {formatMessage({ id: `helpPage.tables.${i}.sub.name` })}
                  </a>
                </h1>
                <p>
                  {formatMessage({
                    id: `helpPage.tables.${i}.sub.description`,
                  })}
                </p>
              </div>
            </Panel>
          ))}
        </Collapse>
        <div className="framed">
          <h1 className="text-center">{formatMessage({ id: 'helpPage.comparison' })}</h1>
          <Collapse>
            <Panel key="1" header={formatMessage({ id: 'helpPage.tables.st.title' })}>
              <div className="text-center">
                <table className="normal-table">
                  <thead>
                    <tr>
                      <th>Stella (st)</th>
                      <th>Overjoy (★★)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0</td>
                      <td>(発狂)★19上~★20</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td rowSpan={2}>3</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>4</td>
                    </tr>
                    <tr>
                      <td rowSpan={3}>4</td>
                    </tr>
                    <tr>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>6</td>
                    </tr>
                    <tr>
                      <td rowSpan={4}>5</td>
                    </tr>
                    <tr>
                      <td>7</td>
                    </tr>
                    <tr>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>9</td>
                    </tr>
                    <tr>
                      <td rowSpan={3}>6</td>
                    </tr>
                    <tr>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>11~12</td>
                    </tr>
                    <tr>
                      <td>7</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Panel>
            <Panel key="2" header={formatMessage({ id: 'helpPage.tables.sl.title' })}>
              <div className="text-center">
                <table className="normal-table">
                  <thead>
                    <tr>
                      <th>Satellite (sl)</th>
                      <th>発狂BMS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0</td>
                      <td>(通常)☆11~☆12</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>★1~★2下</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>★2中~★4下</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>★4中~★5</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>★6~★7</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>★8~★9</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>★10~★11中</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>★11上~★13下</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>★13中~★14</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>★15~★16下</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>★16中~★17下</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>★17中~★18中</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>★18上~★19中</td>
                    </tr>
                    <tr>
                      <td>st0</td>
                      <td>★19上~★20</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Panel>
            <Panel key="3" header={formatMessage({ id: 'helpPage.tables.dpst.title' })}>
              <div className="text-center">
                <table className="normal-table">
                  <thead>
                    <tr>
                      <th>Stella (st)</th>
                      <th>DP Overjoy (★★)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0</td>
                      <td>★★1~★★2</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>★★3~★★4</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>★★5</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>★★6</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>★★7</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>★★8</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>★★9~</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Panel>
            <Panel key="4" header={formatMessage({ id: 'helpPage.tables.dp.title' })}>
              <div className="text-center">
                <table className="normal-table">
                  <thead>
                    <tr>
                      <th>Satellite (sl)</th>
                      <th>発狂DPBMS (★)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0</td>
                      <td>δ11</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>★1</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>★2</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>★3</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>★4</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>★5</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>★6</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>★7</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>★8</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>★9</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>★10</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default Help;
