/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Affix, Card, Collapse, Menu, Radio } from 'antd';
import { Link, RouteComponentProps } from 'react-router-dom';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useIntl } from 'react-intl';
import Submit from './submit';
import RevisionSubmit from './revisionSubmit';
import VoteTable from './table';
import checkTable from '../../utils/checkTable';
import RevisionTable from './revisionTable';
import { useRecoilValue } from 'recoil';
import { userState } from '../../store/atom';
import Paragraph from 'antd/lib/typography/Paragraph';

const { Panel } = Collapse;
const { Item } = Menu;

type TableType = 'st' | 'sl' | 'dpst' | 'dp' | 'fr';
type ModeProps = 'vote' | 'revision';

interface MatchParams {
  tableid: TableType;
  userid: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

const customPanelStyle: React.CSSProperties = {
  backgroundColor: 'rgba(24, 144, 255, .25)',
};

const listStyle: React.CSSProperties = {
  listStyleType: 'disc',
  listStylePosition: 'inside',
};

const Vote: React.FC<MatchProps> = ({ match }) => {
  const [table, setTable] = useState<string>(match.params.tableid);
  const [mode, setMode] = useState<ModeProps>('vote');

  const user = useRecoilValue(userState);
  const { formatMessage } = useIntl();

  const onChange = (event: RadioChangeEvent) => {
    setMode(event.target.value);
  };

  useEffect(() => {
    setTable(match.params.tableid);
  }, [match.params.tableid]);

  if (!checkTable(match.params.tableid)) return <div />;

  return (
    <div>
      <Affix>
        <Menu mode="horizontal" defaultSelectedKeys={[match.params.tableid]}>
          <Item key="st">
            <Link to="st">STELLA</Link>
          </Item>
          <Item key="sl">
            <Link to="sl">SATELLITE</Link>
          </Item>
          <Item key="dpst">
            <Link to="dpst">DP STELLA</Link>
          </Item>
          <Item key="dp">
            <Link to="dp">DP SATELLITE</Link>
          </Item>
          <Item key="fr">
            <Link to="fr">EXOPLANET</Link>
          </Item>
        </Menu>
      </Affix>
      <div className="normal-container">
        {match.params.tableid === 'fr' ? (
          <Card>
            <img
              src="/exoplanet.png"
              alt="exoplanet"
              width="128px"
              height="128px"
              style={{ display: 'block', margin: 'auto' }}
            />
            <Paragraph>
              <h4 style={{ textAlign: 'center' }}>
                {formatMessage({ id: 'votePage.introduceExoplanet' })}
              </h4>
              <ul style={listStyle}>
                {[0, 1, 2, 3, 4, 5, 6, 7].map((i, index) => (
                  <li key={index.toString()}>
                    {formatMessage({ id: `votePage.exoplanetRules.${i}` })}
                  </li>
                ))}
              </ul>
            </Paragraph>
            <Collapse>
              <Panel
                key="1"
                header={`${formatMessage({ id: 'helpPage.tables.fr.title' })} ${formatMessage({
                  id: 'helpPage.comparison',
                })}`}
              >
                <div className="text-center">
                  <table className="normal-table">
                    <thead>
                      <tr>
                        <th>EXOPLANET (fr)</th>
                        <th>Satellite/Stella (sl, st)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0</td>
                        <td>sl0↓</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>sl0~sl1</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>sl2~sl3</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>sl4~sl5</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>sl6~sl7</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>sl8~sl9</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>sl10~sl11</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>sl12~st0</td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>st1~st2</td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>st3~st4</td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>st5~st6</td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <td>st7~st8</td>
                      </tr>
                      <tr>
                        <td>12</td>
                        <td>st9~</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Panel>
            </Collapse>
          </Card>
        ) : null}
        <Collapse>
          <Panel
            key="1"
            header={formatMessage({ id: 'votePage.submitTitle' })}
            style={customPanelStyle}
          >
            {!user.userid ? (
              formatMessage({ id: 'votePage.requireLogin' })
            ) : (
              <div>
                <Submit table={table} />
              </div>
            )}
          </Panel>
          <Panel
            key="2"
            header={formatMessage({ id: 'votePage.revisionSubmitTitle' })}
            style={customPanelStyle}
          >
            {!user.userid ? (
              formatMessage({ id: 'votePage.requireLogin' })
            ) : (
              <RevisionSubmit table={table} />
            )}
          </Panel>
        </Collapse>
      </div>
      <div className="normal-container text-center">
        <Radio.Group onChange={onChange} defaultValue="vote" buttonStyle="solid" size="large">
          <Radio.Button value="vote">{formatMessage({ id: 'votePage.toggleTable' })}</Radio.Button>
          <Radio.Button value="revision">
            {formatMessage({ id: 'votePage.toggleRevisionTable' })}
          </Radio.Button>
        </Radio.Group>
      </div>
      {mode === 'vote' ? <VoteTable table={table} /> : <RevisionTable table={table} />}
    </div>
  );
};

export default Vote;
