import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { putRequest } from '../../utils/ajax';

interface MatchProps {
  submission: number;
  table: string;
}

const { Item } = Form;

const CommentForm: React.FC<MatchProps> = ({ submission, table }) => {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(false);
  const history = useHistory();
  const { formatMessage } = useIntl();

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, table]);

  const onFinish = async (values: any) => {
    if (values.comment && values.comment.length > 200) {
      message.error(formatMessage({ id: 'error.TOOLONGCOM' }));
    } else if (values.comment) {
      setDisable(true);
      const result = await putRequest(`/api/vote/comment/${submission}`, {
        ...values,
        table,
      });

      if (!result.success) {
        if (result.error) message.error(formatMessage({ id: `error.${result.error}` }));
        else message.error('Unknown Error.');
        setDisable(false);
      } else {
        message.success('OK');
        history.go(0);
      }
    } else {
      message.error('Unknown Error.');
    }
  };

  return (
    <Form form={form} wrapperCol={{ span: 24 }} onFinish={onFinish}>
      <Item style={{ marginBottom: 0 }}>
        <Item
          name="comment"
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'votePage.revisionSubmitForm.commentError',
              }),
            },
          ]}
          style={{ display: 'inline-block', width: '80%' }}
        >
          <Input
            placeholder={formatMessage({
              id: 'songPage.commentPlaceholder',
            })}
          />
        </Item>
        <Button
          style={{ display: 'inline-block', width: '20%' }}
          htmlType="submit"
          disabled={disable}
          type="primary"
        >
          <CheckOutlined />
        </Button>
      </Item>
    </Form>
  );
};

export default CommentForm;
