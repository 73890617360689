import React, { useState, useEffect } from 'react';
import { Affix, Input, Menu, Table, message, TablePaginationConfig } from 'antd';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ClearProps from '../../props/clear';
import StellawayProps from '../../props/stellaway';
import ClearLamp from './clearlamp';
import checkTable from '../../utils/checkTable';
import customSort from '../../utils/customSort';
import levelArray from '../../utils/levelArray';
import irUrl from '../../utils/irUrl';
import { postRequest } from '../../utils/ajax';

const { Search } = Input;
const { Item } = Menu;
const { Column } = Table;

type TableType = 'st' | 'sl' | 'dpst' | 'dp' | 'fr';

interface MatchParams {
  tableid: TableType;
  userid: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

interface UserProps {
  nickname: string;
  playerName: string;
  dan: number;
  dan2: number;
  stairway: ClearProps[];
  stairway2: ClearProps[];
  stairway3: ClearProps[];
  stairway4: ClearProps[];
  stairway5: ClearProps[];
  playData: StellawayProps;
  playData2: StellawayProps;
  playData3: StellawayProps;
  playData4: StellawayProps;
  playData5: StellawayProps;
}

const defaultUserProps: UserProps = {
  nickname: '',
  playerName: '',
  dan: -1,
  dan2: -1,
  stairway: [],
  stairway2: [],
  stairway3: [],
  stairway4: [],
  stairway5: [],
  playData: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  playData2: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  playData3: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  playData4: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  playData5: {
    userLevel: 0,
    userSkill: 0,
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
};

const minirRegex = /^([0-9a-f]{8})-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{12})$/;

const User: React.FC<MatchProps> = ({ match }) => {
  const [clearData, setClearData] = useState<ClearProps[]>([]);
  const [userData, setUserData] = useState<UserProps>(defaultUserProps);
  const [loading, setLoading] = useState(false);
  const [filteredValue, setFilteredValue] = useState<any>([]);
  const [user, setUser] = useState('');

  const history = useHistory();
  const { formatMessage } = useIntl();

  useEffect(() => {
    (async () => {
      const onUserChange = async () => {
        const result = await postRequest(`/api/user/${match.params.userid}`);

        if (!result.success) {
          if (result.error) {
            message.error(formatMessage({ id: `error.${result.error}` }));
          } else message.error('Unknown Error.');
        } else {
          const {
            dan,
            dan2,
            nickname,
            playData,
            playData2,
            playData3,
            playData4,
            playData5,
            playerName,
            stairway,
            stairway2,
            stairway3,
            stairway4,
            stairway5,
          } = result;

          setUserData({
            dan,
            dan2,
            nickname,
            playData,
            playData2,
            playData3,
            playData4,
            playData5,
            playerName,
            stairway,
            stairway2,
            stairway3,
            stairway4,
            stairway5,
          });
        }
      };
      const onTableChange = async () => {
        const result = await postRequest('/api/user/list', {
          table: match.params.tableid,
        });

        if (!result.success) {
          if (result.error) {
            message.error(formatMessage({ id: `error.${result.error}` }));
          } else message.error('Unknown Error.');
        } else {
          if (match.params.tableid === 'st') {
            const datasets = result.songs.map((i: any) => ({
              ...i,
              ...userData.stairway.find(
                (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
              ),
            }));
            setClearData(datasets);
          }
          if (match.params.tableid === 'sl') {
            const datasets = result.songs.map((i: any) => ({
              ...i,
              ...userData.stairway2.find(
                (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
              ),
            }));
            setClearData(datasets);
          }
          if (match.params.tableid === 'dpst') {
            const datasets = result.songs.map((i: any) => ({
              ...i,
              ...userData.stairway3.find(
                (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
              ),
            }));
            setClearData(datasets);
          }
          if (match.params.tableid === 'dp') {
            const datasets = result.songs.map((i: any) => ({
              ...i,
              ...userData.stairway4.find(
                (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
              ),
            }));
            setClearData(datasets);
          }
          if (match.params.tableid === 'fr') {
            const datasets = result.songs.map((i: any) => ({
              ...i,
              ...userData.stairway5.find(
                (j: ClearProps) => i.md5 === j.md5 || i.sha256 === j.sha256,
              ),
            }));
            setClearData(datasets);
          }
        }
      };
      setLoading(true);
      setClearData([]);
      setFilteredValue([]);
      if (user !== match.params.userid) {
        await onUserChange();
        setUser(match.params.userid);
      }
      await onTableChange();
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    match.params.tableid,
    match.params.userid,
    userData.stairway,
    userData.stairway2,
    userData.stairway3,
    userData.stairway4,
    userData.stairway5,
  ]);

  const onSearch = (value: string) => {
    if (value) {
      history.push(`${history.location.pathname}/${value}`);
    }
  };

  const onChange = (
    tablePagination: TablePaginationConfig,
    filters: Record<string, (string | number | boolean)[] | null>,
  ) => {
    setFilteredValue(filters.level);
  };

  if (!checkTable(match.params.tableid)) return <div />;

  return (
    <div>
      <Affix>
        <Menu mode="horizontal" defaultSelectedKeys={[match.params.tableid]}>
          <Item key="st">
            <Link to={`../st/${match.params.userid}`}>STELLA</Link>
          </Item>
          <Item key="sl">
            <Link to={`../sl/${match.params.userid}`}>SATELLITE</Link>
          </Item>
          <Item key="dpst">
            <Link to={`../dpst/${match.params.userid}`}>DP STELLA</Link>
          </Item>
          <Item key="dp">
            <Link to={`../dp/${match.params.userid}`}>DP SATELLITE</Link>
          </Item>
          <Item key="fr">
            <Link to={`../fr/${match.params.userid}`}>EXOPLANET</Link>
          </Item>
        </Menu>
      </Affix>
      <div className="normal-container">
        <div className="framed text-center">
          {match.params.tableid === 'st' || match.params.tableid === 'sl' ? (
            <h1 className={`skill${userData.dan}`}>
              {userData.nickname} ({match.params.userid})
            </h1>
          ) : (
            <h1 className={`skill${(Number(userData.dan2) + 12).toString()}`}>
              {userData.nickname} ({match.params.userid})
            </h1>
          )}
          {match.params.tableid === 'st' ? (
            <h2>
              Lv.{userData.playData.userLevel} ({userData.playData.exp}/
              {(userData.playData.userLevel + 1) * (userData.playData.userLevel + 1) * 10})
            </h2>
          ) : null}
          {match.params.tableid === 'sl' ? (
            <h2>
              Lv.{userData.playData2.userLevel} ({userData.playData2.exp}/
              {(userData.playData2.userLevel + 1) * (userData.playData2.userLevel + 1) * 10})
            </h2>
          ) : null}
          {match.params.tableid === 'dpst' ? (
            <h2>
              Lv.{userData.playData3.userLevel} ({userData.playData3.exp}/
              {(userData.playData3.userLevel + 1) * (userData.playData3.userLevel + 1) * 10})
            </h2>
          ) : null}
          {match.params.tableid === 'dp' ? (
            <h2>
              Lv.{userData.playData4.userLevel} ({userData.playData4.exp}/
              {(userData.playData4.userLevel + 1) * (userData.playData4.userLevel + 1) * 10})
            </h2>
          ) : null}
          {match.params.tableid === 'fr' ? (
            <h2>
              Lv.{userData.playData5.userLevel} ({userData.playData5.exp}/
              {(userData.playData5.userLevel + 1) * (userData.playData5.userLevel + 1) * 10})
            </h2>
          ) : null}
        </div>
        <div className="framed">
          <ClearLamp table={match.params.tableid} data={clearData} emulator={userData.playerName} />
        </div>
      </div>
      <div style={{ textAlign: 'right' }}>
        <Search
          placeholder={formatMessage({ id: 'userPage.searchPlaceholder' })}
          onSearch={onSearch}
          enterButton
          loading={loading}
          style={{
            width: '50%',
          }}
        />
      </div>
      <Table<ClearProps>
        bordered
        scroll={{ x: 720 }}
        dataSource={clearData}
        loading={loading}
        pagination={false}
        rowKey="_id"
        size="small"
        onChange={onChange}
        rowClassName={(record) => `${userData.playerName}-clear-${record.clear}`}
      >
        <Column<ClearProps>
          key="level"
          title="Lv"
          dataIndex="level"
          filters={levelArray[match.params.tableid].map((i) => {
            return {
              text: `${match.params.tableid}${i.toString()}`,
              value: i.toString(),
            };
          })}
          onFilter={(value, record) => value === record.level.toString()}
          filteredValue={filteredValue}
          sorter={customSort('level')}
          width={80}
        />
        <Column<ClearProps>
          ellipsis
          key="title"
          title="Title"
          dataIndex="title"
          render={(plaintext, record) => (
            <a
              href={
                userData.playerName === 'lr2'
                  ? irUrl(record.md5, 'lr2')
                  : irUrl(record.sha256, 'beatoraja')
              }
            >
              {record.title}
            </a>
          )}
          width={240}
        />
        <Column<ClearProps>
          key="point"
          title="Point"
          dataIndex="point"
          sorter={customSort('point')}
          width={80}
        />
        {!minirRegex.test(match.params.userid) ? (
          <Column<ClearProps>
            key="rank"
            title="Rank"
            dataIndex="rank"
            sorter={customSort('rank')}
            width={80}
          />
        ) : null}
        <Column<ClearProps>
          key="percent"
          title="%"
          dataIndex="percent"
          render={(plaintext, record) => record.percent?.toFixed(2)}
          sorter={customSort('percent')}
          width={80}
        />
        {!minirRegex.test(match.params.userid) ? (
          <Column<ClearProps>
            key="minbp"
            title="BP"
            dataIndex="minbp"
            sorter={customSort('minbp')}
            width={80}
          />
        ) : null}
        <Column<ClearProps>
          key="score"
          title="EX"
          dataIndex="score"
          sorter={customSort('score')}
          width={80}
        />
      </Table>
    </div>
  );
};

export default User;
