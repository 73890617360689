import React from 'react';
import { TwitterOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { Typography } from 'antd';

const { Title, Paragraph, Link } = Typography;

const Help: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="normal-container">
      <Typography>
        <Title>{formatMessage({ id: 'contact.notification.title' })}</Title>
        <Paragraph>
          <ul>
            <li>{formatMessage({ id: 'contact.notification.description.0' })}</li>
            <li>{formatMessage({ id: 'contact.notification.description.1' })}</li>
            <li>{formatMessage({ id: 'contact.notification.description.2' })}</li>
            <li>{formatMessage({ id: 'contact.notification.description.3' })}</li>
            <li>{formatMessage({ id: 'contact.notification.description.4' })}</li>
            <li>{formatMessage({ id: 'contact.notification.description.5' })}</li>
            <li>{formatMessage({ id: 'contact.notification.description.6' })}</li>
          </ul>
        </Paragraph>
        <Title level={2}>
          <Link href="https://discord.com/invite/44XgsF4">
            Discord ({formatMessage({ id: 'contact.discord' })})
          </Link>
          <Link href="https://twitter.com/luxury__a">
            <TwitterOutlined style={{ color: '#1da1f2' }} />
            &nbsp;Twitter ({formatMessage({ id: 'contact.twitter' })})
          </Link>
        </Title>
      </Typography>
    </div>
  );
};

export default Help;
