import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { postRequest } from '../utils/ajax';
import Spinning from '../components/Spinning';

const { Panel } = Collapse;

interface NoticeComponentProps {
  lang: string;
}

interface NoticeContentProps {
  title: string;
  description: string;
}

interface NoticeProps {
  en: NoticeContentProps;
  ja: NoticeContentProps;
  ko: NoticeContentProps;
}

const Notice: React.FC<NoticeComponentProps> = ({ lang }) => {
  const [loading, setLoading] = useState(true);
  const [noticeArray, setNoticeArray] = useState<NoticeProps[]>([]);

  useEffect(() => {
    const onLoad = async () => {
      const result = await postRequest('/notice');
      setNoticeArray(result);
      setLoading(false);
    };
    setLoading(true);
    onLoad();
  }, []);

  return loading ? (
    <Spinning />
  ) : (
    <Collapse accordion>
      {noticeArray.map((i: any, index) => (
        <Panel key={index.toString()} header={i[lang].message}>
          {i[lang].description}
        </Panel>
      ))}
    </Collapse>
  );
};

export default Notice;
