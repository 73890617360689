import React, { FC } from 'react';
import { Spin } from 'antd';

const Spinning: FC = () => (
  <div style={{ textAlign: 'center' }}>
    <Spin size="large" />
  </div>
);

export default Spinning;
