/* eslint-disable camelcase */
import { defaultUserState } from '../store/atom';
import UserProps from './user';

export const defaultSongProps: SongProps = {
  entryNo: 0,
  md5: '',
  sha256: '',
  title: '',
  artist: '',
  url: '',
  url_diff: '',
  level: 0,
  proposer: defaultUserState,
  comment: '',
  zureVerification: false,
  originMd5: '',
  publishedTime: new Date(0),
  voters: [],
  voters2: [],
  comments: [],
  voteYes: 0,
  voteNeutral: 0,
  voteNo: 0,
  votePercent: 0,
  voteResult: '',
  voteVersion: 1,
  adult: false,
};

export interface Voter {
  voter: UserProps;
  voterLevel: string;
  voterResult: string;
  voterComment: string;
}

export interface VoterOptionProps {
  difficulty: string;
  personDiff: boolean;
}

export interface Voter2 {
  voter: UserProps;
  voterOption: VoterOptionProps;
  voterResult: string;
  voterComment: string;
}

export interface Comments {
  voter: UserProps;
  voterComment: string;
}

interface SongProps {
  entryNo: number;
  md5: string;
  sha256: string;
  title: string;
  artist: string;
  url: string;
  url_diff: string;
  level: number;
  proposer: UserProps;
  comment: string;
  zureVerification: boolean;
  originMd5: string;
  publishedTime: Date;
  voters: Voter[];
  voters2: Voter2[];
  comments: Comments[];
  voteYes: number;
  voteNeutral: number;
  voteNo: number;
  votePercent: number;
  voteResult: string;
  voteVersion: number;
  adult: boolean;
}

export default SongProps;
