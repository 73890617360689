import React, { FC } from 'react';
import { Collapse } from 'antd';
import { useIntl } from 'react-intl';

const { Panel } = Collapse;

const SkillSimulator: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Collapse>
        <Panel key="1" header="Stella Skill Simulator">
          <div className="text-center">
            <h1>
              <a href="/[course]Stella Skill Simulator v3.lr2crs">
                {formatMessage({ id: 'skillPage.stellaTitle' })}
              </a>
            </h1>
            <h1>
              <a href="https://docs.google.com/spreadsheets/d/1xxQ-afC80vxi5i1WqnGGeHvNymaAtKLbrS_yD6BpvXY/edit#gid=347092130">
                {formatMessage({ id: 'skillPage.stellaDescription' })}
              </a>
            </h1>
          </div>
          <Collapse accordion>
            <Panel key="1" header="st0">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st0</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>stairway to the universe [laner]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>So Fluffy!! [snuggly]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>-+ [INFERNO]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>ShootingStar [MoonStrike!!]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="2" header="st1">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st1</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>永劫綺譚の純潔葬花 -Yellow Lily-</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>スピカ [Supernova]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>ハニエルのうた [EXTRA+]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>QSHELL (QPython)</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="3" header="st2">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st2</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>僕が見た世界 [Finale]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>ある星降る夜空の下 [skyfall]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>FUTURE 1999 [2299.06.15]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>秩序の秋桜 [ありがたや+]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="4" header="st3">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st3</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>夢、幻。 [SSSSA]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>サカサマの空に在ったのは [SP PUDDLE]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>ZENITHALIZE [Monsther]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>虹夜パレッド [Pentagon]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="5" header="st4">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st4</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Absolute Future [Infinity]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>arosonik&apos;A [ヒム子]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>スーパーイザナギオブジェクト [Other]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Crimson Liar(&quot;Sadistic girl&quot; Mix) -Nightmare-</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="6" header="st5">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st5</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>白の魔法の光 [INFERNO]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>PLASTIC GIRLS (ft. Seorryang) [Other]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>VG (Radio Edit) [Hi]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>☆をかけるアドベンチャー ～ we are forever friends! ～ [3844.09.29]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="7" header="st6">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st6</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Unleashed World [INSANE]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    {/* eslint-disable-next-line no-irregular-whitespace */}
                    <td>先手必勝戦闘機　鳳 [Vent Divin]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>SYNTHETICS [Hexagon]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>overtrue [Other]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="8" header="st7">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st7</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Hypertext Transfer Protocol [Other]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>+84 [+700]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Vorota Collapse [Fatality]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Finixe -LEGENDA EST A MYTH-</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="9" header="st8">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st8</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>tinnitus [Other]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Revelator -Revenger-</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Life is PIANO [Other]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>天空の城ヴェリエル [EX]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="10" header="st9">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st9</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Icicle Stinger -Solar Eclipse-</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>WHIRLWIND [END]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>diSTractiOn PoInT [diRaY ZonE]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Amnehilesie -Ragnarok-</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="11" header="st10">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st10</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Triumphal Return [TrueEnd]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>CYCLONE [Other]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>VENDeTTA -Ragnarok-</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Nhelv -Ragnarok-</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="12" header="st11">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>st11</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Celestial Axes [Other]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Defeat awaken battle ship (SP DESTROYER)</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>PEACE BREAKER [PURGE]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Schwerkraft [Dioga Gravidon]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </Collapse>
        </Panel>
        <Panel key="2" header="Satellite Skill Analyzer">
          <div className="text-center">
            <h1>
              <a href="/[course]Satellite Skill Analyzer v2.lr2crs">
                {formatMessage({ id: 'skillPage.satelliteTitle' })}
              </a>
            </h1>
            <h1>
              <a href="https://docs.google.com/spreadsheets/d/1SJbO7hXCSpGR4DEJErZV5bqDS-4KbcEQnR4Oryog1nk/edit?usp=sharing">
                {formatMessage({ id: 'skillPage.satelliteDescription' })}
              </a>
            </h1>
          </div>
          <Collapse accordion>
            <Panel key="1" header="sl0">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl0</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>翼の折れた天使たちのレクイエム [Another]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>アトロポスと最果の探求者</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Life is PIANO [Another]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>BLAZE YOUR SOUL [SP ANOTHER]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="2" header="sl1">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl1</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>IKAROS [HARD]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Berry Go!! [Esteem and Love]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Latch (Flip-Flop) [Spinner+]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Brave My Soul -Flareness Heart-</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="3" header="sl2">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl2</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Sea Breeze Memory -Summer Vacation-</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Apocaliptix [Doomsayer]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Love☆Spellcard [ZOI]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Re/Im [ZOI]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="4" header="sl3">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl3</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>MAGENTA POTION [INSANE]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Wicked Laboratory</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>GLITCH SWITCH [SP INSANE]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Disorder -ANOTHER-</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="5" header="sl4">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl4</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>EVERLASTING HAPPiNESS↑ [ZOI]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Mr. BLACKRUM [SP RUNOTHER -Cuba Libre-]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Transfixation [STERRITE]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>squartatrice [HARD]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="6" header="sl5">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl5</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Signager -HONGKONG EXPRESS_FIX-</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>banana man [HARD]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Wizdomiot [ABNORMAL]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Ground Z.E.R.O. [ZOI]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="7" header="sl6">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl6</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Awaking Beat-From the next generation- [W]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>平衡世界 Parallel World [HARDEST]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Absolute Future [ANOTHER+]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>白菊 [Snother]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="8" header="sl7">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl7</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Remembrance [INSANE]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Astronomical Twilight [Sunrise]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Doppelganger [Eb]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>energy night [JOY]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="9" header="sl8">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl8</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Happiness Magical Kanon [HAPPY]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>ラケシスと永遠の祈祷者 [Sanctuary]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Empress of Raizze [ストレッチ]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>DESPERATE -Last Battle-</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="10" header="sl9">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl9</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Free Hand zero [ANOTHER*]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Andro [F]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>永劫綺譚の純潔葬花 -Red Lily-</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>ニニ - ニサシゴ -</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="11" header="sl10">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl10</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>踊る水飛沫 [KF7]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Central Nucleus [INFERNO]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>温泉大作戦 [温泉名人]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Andromeda [yumether]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="12" header="sl11">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl11</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>SYNTHETICS [CYBERTIC ETUDE]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Wavetapper [ATHLETIC]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Death Opera [The Phantom]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>ヒメタイプ [LAPITHER]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="13" header="sl12">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>sl12</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Sparkling☆Sunshine!! [INSANE]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>ting ting [belong]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Total Eclipse of the Sun -Nightmare-</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Terpsichore [Li]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </Collapse>
        </Panel>
        <Panel key="3" header="DP Satellite Skill Analyzer">
          <div className="text-center">
            <h1>
              <a href="/[course]DP Satellite Skill Analyzer.lr2crs">
                {formatMessage({ id: 'skillPage.dpsatelliteTitle' })}
              </a>
            </h1>
            <h1>
              <a href="https://docs.google.com/spreadsheets/d/1BiekRtFgWsJG4Uh7-cFR9kO8j4GIchUtVkf5ZYFjY7Q/edit#gid=1856418955">
                {formatMessage({ id: 'skillPage.dpsatelliteDescription' })}
              </a>
            </h1>
          </div>
          <Collapse accordion>
            <Panel key="1" header="DPsl0">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl0</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Estella [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>MANHATTAN [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Postliminium [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>ノーザンブルー・デイブリーズ [DP-sagANOTHER]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="2" header="DPsl1">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl1</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>黄昏のメロディア [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>A.O. [DP]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Is This Your Memory? [Another]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Prismrose [DP ANOTHER]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="3" header="DPsl2">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl2</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Event Horizon [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Blooming [DP HYPER]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>XALICE [DP WONDERLAND]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Good Life [y_phANOTHER]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="4" header="DPsl3">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl3</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>☆ウィンドサンバランド☆ -DP ANOTHER-</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>雪肌玉惚少女 [DP Another]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Central Nucleus [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>The Crack of Dawn [SUNRISE]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="5" header="DPsl4">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl4</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>YASAKANI [DP INSANE+]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Eisenschweif (DPA)</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Black or White</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Midnight o'clock [sub another]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="6" header="DPsl5">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl5</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>リリカと魔法の言葉 [DP Lyrical]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Granblaze [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>F4C4D3 [DPA]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>浮世絵横丁</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="7" header="DPsl6">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl6</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>PPBQ</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>PLASTIC GIRLS (ft. Seorryang) [DP MANIAC]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Chaotic☆Dream★Festival [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Old Pal</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="8" header="DPsl7">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl7</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Emptiness [DP ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Summer White [DP NOSTALGIC]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Divided Montage [14KEYS ANOTHER]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Ladymade corestar [14kumanother]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="9" header="DPsl8">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl8</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>IN [HAORSE MUSIC / DP譜面]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>Dreamy Fall [DP INSANE]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>witchcraft [14A]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Evanescent [DP ANOTHER]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="10" header="DPsl9">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl9</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Yorixiro [OyaxiroSama]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>DELIGHTS -EXTREME14-</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>XVOTO</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>Kaleidoscope [DP_Li]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="11" header="DPsl10 (DPst0)">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl10 (DPst0)</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>IKAROS [DP Nymph]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>現実をカットアップしてみた [DPINSA.NE]</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>Danse de Romani</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>ELIMINATOR [DP INSANE]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="12" header="DPsl11 (DPst1)">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl11 (DPst1)</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>#be_fortunate [DPYoitherEASY]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>new earth -dp stella-</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>ALICE IN PICOPICO NIGHT (DP ANOTHER)</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>夜間飛行トラベラー [DP MANIAC]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
            <Panel key="13" header="DPsl12 (DPst2)">
              <table className="normal-table">
                <thead>
                  <tr>
                    <th colSpan={2}>DPsl12 (DPst2)</th>
                  </tr>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1st</td>
                    <td>Apollo [1970.04.11]</td>
                  </tr>
                  <tr>
                    <td>2nd</td>
                    <td>ハードルネッサンバ -DP MID BOSS-</td>
                  </tr>
                  <tr>
                    <td>3rd</td>
                    <td>take me to the moon [DP Disaster]</td>
                  </tr>
                  <tr>
                    <td>4th</td>
                    <td>WHIRLWIND [Twin Blast]</td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </Collapse>
        </Panel>
      </Collapse>
    </>
  );
};

export default SkillSimulator;
