import qs from 'querystring';

export const putRequest = async (
  url: string,
  data: any = null,
  option = {
    contentType: 'json',
    headers: {},
  },
) => {
  const result = await fetch(url, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      ...option.headers,
    },
    body: data === null ? undefined : JSON.stringify(data),
  });
  if (result.ok) {
    if (option.contentType === 'json') {
      const readed = await result.json();
      return readed;
    } else {
      const readed = await result.text();
      return readed;
    }
  } else {
    throw new Error('POST Request Failed');
  }
};

export const postRequest = async (
  url: string,
  data: any = null,
  option = {
    contentType: 'json',
    headers: {},
  },
) => {
  const result = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      ...option.headers,
    },
    body: data === null ? undefined : JSON.stringify(data),
  });
  if (result.ok) {
    if (option.contentType === 'json') {
      const readed = await result.json();
      return readed;
    } else {
      const readed = await result.text();
      return readed;
    }
  } else {
    throw new Error('POST Request Failed');
  }
};

export const uploadRequest = async (url: string, data: any = null) => {
  const result = await fetch(url, {
    method: 'post',
    body: data,
  });
  if (result.ok) {
    const readed = await result.json();
    return readed;
  } else {
    throw new Error('Upload Request Failed');
  }
};

export const getRequest = async (url: string, data: any = null) => {
  let urlWithQuery = url;
  if (data) {
    urlWithQuery += `?${qs.stringify(data)}`;
  }

  const result = await fetch(urlWithQuery, {
    method: 'get',
  });
  if (result.ok) {
    return await result.json();
  } else {
    throw new Error('GET Request Failed');
  }
};
