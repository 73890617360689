import React, { useState, useEffect, FC } from 'react';
import { Collapse, List, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { postRequest } from '../utils/ajax';
import ChartAd from './ChartAd';

const { Panel } = Collapse;
const { Title, Link: TypoLink } = Typography;
const { Item: ListItem } = List;

const Home: FC = () => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dataSource2, setDataSource2] = useState<any[]>([]);
  const [dataSource3, setDataSource3] = useState<any[]>([]);
  const [dataSource4, setDataSource4] = useState<any[]>([]);

  const { formatMessage } = useIntl();

  useEffect(() => {
    (async () => {
      const result = await postRequest('/home');
      setDataSource([...result.source].slice(0, 5)); // test
      setDataSource2([...result.source2].slice(0, 5)); // test
      setDataSource3([...result.source3]); // test
      setDataSource4([...result.source4]); // test
    })();
  }, []);

  return (
    <div className="normal-container text-center">
      <Space direction="vertical">
        <ChartAd />
        <Title level={2}>{formatMessage({ id: 'homePage.tableLink.title' })}</Title>
        <Collapse expandIconPosition="right">
          {['st', 'sl', 'dpst', 'dp', 'fr'].map((i, index) => (
            <Panel
              key={index.toString()}
              header={formatMessage({ id: `helpPage.tables.${i}.title` })}
            >
              <Space direction="vertical">
                <TypoLink
                  href={`https://stellabms.xyz/${i}/table.html`}
                  copyable={{
                    text: `https://stellabms.xyz/${i}/table.html`,
                  }}
                >
                  {formatMessage({ id: `homePage.tableDescription.items.0.title` })}
                </TypoLink>
                <TypoLink
                  href={`https://stellabms.xyz/${i}/table_vote.html`}
                  copyable={{
                    text: `https://stellabms.xyz/${i}/table_vote.html`,
                  }}
                >
                  {formatMessage({ id: `homePage.tableDescription.items.1.title` })}
                </TypoLink>
                <TypoLink
                  href={`https://stellabms.xyz/${i}/table_sub.html`}
                  copyable={{
                    text: `https://stellabms.xyz/${i}/table_sub.html`,
                  }}
                >
                  {formatMessage({ id: `homePage.tableDescription.items.2.title` })}
                </TypoLink>
              </Space>
            </Panel>
          ))}
        </Collapse>
        <List
          header={formatMessage({ id: 'homePage.recentSongHeader' })}
          className="app-home-list"
          itemLayout="horizontal"
          size="small"
          bordered
          dataSource={dataSource}
          renderItem={(item) => (
            <ListItem>
              <Link to={`/s/${item.table}/${item.entryNo}`}>
                <span style={{ backgroundColor: 'rgba(24, 144, 255, .25)' }}>
                  {`[${item.table.toUpperCase()}]`}
                </span>
                {` ${dayjs(item.publishedTime).format('MM/DD')} ${item.table}${item.level} ${
                  item.title
                }`}
              </Link>
            </ListItem>
          )}
        />
        <List
          className="app-home-list"
          itemLayout="horizontal"
          size="small"
          bordered
          dataSource={dataSource3}
          renderItem={(item) => (
            <ListItem>
              <Link to={`/s/${item.table}/${item.entryNo}`}>
                <span style={{ backgroundColor: 'rgba(24, 144, 255, .25)' }}>
                  {`[${item.table.toUpperCase()}]`}
                </span>
                {` ${dayjs(item.publishedTime).format('MM/DD')} ${item.table}${item.level} ${
                  item.title
                }`}
              </Link>
            </ListItem>
          )}
        />
        <List
          header={formatMessage({ id: 'homePage.recentRevisionHeader' })}
          className="app-home-list"
          itemLayout="horizontal"
          size="small"
          bordered
          dataSource={dataSource2}
          renderItem={(item) => (
            <ListItem>
              <Link to={`/u/${item.table}/${item.entryNo}`}>
                <span style={{ backgroundColor: 'rgba(24, 144, 255, .25)' }}>
                  {`[${item.table.toUpperCase()}]`}
                </span>
                {` ${dayjs(item.publishedTime).format('MM/DD')} ${(() => {
                  if (item.type === 'transfer' && (item.table === 'st' || item.table === 'dpst'))
                    return formatMessage({
                      id: `songPage.type.${item.type}.1`,
                    });
                  if (item.type === 'transfer' && (item.table === 'sl' || item.table === 'dp'))
                    return formatMessage({
                      id: `songPage.type.${item.type}.0`,
                    });
                  return formatMessage({
                    id: `songPage.type.${item.type}`,
                  });
                })()} ${item.table}${item.level}${
                  item.changeTo !== undefined && item.changeTo !== null ? `→${item.changeTo}` : ''
                } ${item.title}`}
              </Link>
            </ListItem>
          )}
        />
        <List
          className="app-home-list"
          itemLayout="horizontal"
          size="small"
          bordered
          dataSource={dataSource4}
          renderItem={(item) => (
            <ListItem>
              <Link to={`/u/${item.table}/${item.entryNo}`}>
                <span style={{ backgroundColor: 'rgba(24, 144, 255, .25)' }}>
                  {`[${item.table.toUpperCase()}]`}
                </span>
                {` ${dayjs(item.publishedTime).format('MM/DD')} ${(() => {
                  if (item.type === 'transfer' && (item.table === 'st' || item.table === 'dpst'))
                    return formatMessage({
                      id: `songPage.type.${item.type}.1`,
                    });
                  if (item.type === 'transfer' && (item.table === 'sl' || item.table === 'dp'))
                    return formatMessage({
                      id: `songPage.type.${item.type}.0`,
                    });
                  return formatMessage({
                    id: `songPage.type.${item.type}`,
                  });
                })()} ${item.table}${item.level}${
                  item.changeTo !== undefined && item.changeTo !== null ? `→${item.changeTo}` : ''
                } ${item.title}`}
              </Link>
            </ListItem>
          )}
        />
      </Space>
    </div>
  );
};

export default Home;
