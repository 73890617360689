import React, { useEffect, useState } from 'react';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Affix, Menu, Select, Tag, message } from 'antd';
import { Link, useHistory, RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import checkTable from '../utils/checkTable';
import Spinning from '../components/Spinning';
import { postRequest } from '../utils/ajax';

const { Item } = Menu;
const { Option } = Select;

type TableType = 'st' | 'sl' | 'dpst' | 'dp' | 'fr';

interface MatchParams {
  tableid: TableType;
  yearid: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

interface MapProps {
  level: number | string;
  title: string;
  voteYes?: number;
  voteNeutral?: number;
  voteNo?: number;
  votePercent?: number;
  submission: number;
  isPublish: boolean;
}

interface HistoryProps {
  date: string;
  songs: MapProps[];
  tags: string[];
}

const tableLayout = {
  level: {
    width: '90px',
  },
  title: {
    width: '240px',
  },
  voteYes: {
    width: '50px',
  },
  voteNeutral: {
    width: '50px',
  },
  voteNo: {
    width: '50px',
  },
  votePercent: {
    width: '60px',
  },
  isPublish: {
    width: '50px',
  },
};

const yearArray: number[] = [];

for (let i = 2018; i <= new Date().getFullYear(); i += 1) {
  yearArray.push(i);
}

const History: React.FC<MatchProps> = ({ location, match }) => {
  const [loading, setLoading] = useState(true);
  const [historyArray, setHistoryArray] = useState<HistoryProps[]>([]);
  const history = useHistory();
  const { formatMessage } = useIntl();

  const onChange = (value: string) => {
    history.push(value);
  };

  useEffect(() => {
    const onTableChange = async () => {
      const result = await postRequest('/api/history', {
        table: match.params.tableid,
        year: match.params.yearid,
      });
      if (!result.success) {
        if (result.error) {
          message.error(formatMessage({ id: `error.${result.error}` }));
        } else message.error('Unknown Error.');
      } else {
        setHistoryArray([...result.source]);
      }
      setLoading(false);
    };
    setHistoryArray([]);
    setLoading(true);
    onTableChange();
    setLoading(false);
  }, [match.params.tableid, match.params.yearid]);

  if (!checkTable(match.params.tableid)) return null;

  return loading ? (
    <Spinning />
  ) : (
    <div>
      <Affix>
        <div>
          <Select
            defaultValue={match.params.yearid}
            style={{ padding: 1, width: '100%' }}
            onChange={onChange}
          >
            {yearArray.map((i) => (
              <Option key={i.toString()} value={i.toString()}>
                <Link to={i.toString()}>{i}</Link>
              </Option>
            ))}
          </Select>
          <Menu mode="horizontal" defaultSelectedKeys={[match.params.tableid]}>
            <Item key="st">
              <Link to={`../st/${match.params.yearid}`}>STELLA</Link>
            </Item>
            <Item key="sl">
              <Link to={`../sl/${match.params.yearid}`}>SATELLITE</Link>
            </Item>
            <Item key="dpst">
              <Link to={`../dpst/${match.params.yearid}`}>DP STELLA</Link>
            </Item>
            <Item key="dp">
              <Link to={`../dp/${match.params.yearid}`}>DP SATELLITE</Link>
            </Item>
            <Item key="fr">
              <Link to={`../fr/${match.params.yearid}`}>EXOPLANET</Link>
            </Item>
          </Menu>
        </div>
      </Affix>
      {historyArray.map((i, index) => {
        const mapToSongs = [...i.songs];
        return (
          <div key={`history-${index}`}>
            <h1>
              {i.date}
              &nbsp;
              {i.tags.map((j) => {
                switch (j) {
                  case 'update':
                    return (
                      <Tag key={j} color="green">
                        {formatMessage({ id: 'historyPage.tag.update' })}
                      </Tag>
                    );
                  case 'changechart':
                    return (
                      <Tag key={j} color="orange">
                        {formatMessage({ id: 'historyPage.tag.changechart' })}
                      </Tag>
                    );
                  case 'changelink':
                    return (
                      <Tag key={j} color="orange">
                        {formatMessage({ id: 'historyPage.tag.changelink' })}
                      </Tag>
                    );
                  case 'cancel':
                    return (
                      <Tag key={j} color="red">
                        {formatMessage({ id: 'historyPage.tag.cancel' })}
                      </Tag>
                    );
                  case 'delete':
                    return (
                      <Tag key={j} color="red">
                        {formatMessage({ id: 'historyPage.tag.delete' })}
                      </Tag>
                    );
                  case 'deletesub':
                    return (
                      <Tag key={j} color="red">
                        {formatMessage({ id: 'historyPage.tag.deletesub' })}
                      </Tag>
                    );
                  default:
                    return null;
                }
              })}
            </h1>
            <div style={{ width: '100%', overflowX: 'auto' }}>
              <table className="normal-table" style={{ margin: 0, width: '100%' }}>
                <thead>
                  <tr>
                    <th style={tableLayout.level}>Lv</th>
                    <th style={tableLayout.title}>{formatMessage({ id: 'historyPage.th.0' })}</th>
                    {i.tags.indexOf('update') < 0 ? null : (
                      <th style={tableLayout.voteYes}>
                        {formatMessage({ id: 'historyPage.th.1' })}
                      </th>
                    )}
                    {i.tags.indexOf('update') < 0 || match.params.yearid === '2018' ? null : (
                      <th style={tableLayout.voteNeutral}>
                        {formatMessage({ id: 'historyPage.th.2' })}
                      </th>
                    )}
                    {i.tags.indexOf('update') < 0 ? null : (
                      <th style={tableLayout.voteNo}>
                        {formatMessage({ id: 'historyPage.th.3' })}
                      </th>
                    )}
                    {i.tags.indexOf('update') < 0 ? null : (
                      <th style={tableLayout.votePercent}>%</th>
                    )}
                    {i.tags.indexOf('update') < 0 ? null : (
                      <th style={tableLayout.isPublish}>
                        {formatMessage({ id: 'historyPage.th.4' })}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {mapToSongs.map((j, index) => (
                    <tr key={`history-item-${index}`}>
                      <td style={tableLayout.level}>{j.level}</td>
                      <td style={tableLayout.title}>
                        <Link to={`/s/${match.params.tableid}/${j.submission}`}>{j.title}</Link>
                      </td>
                      {typeof j.voteYes === 'number' ? (
                        <td style={tableLayout.voteYes}>{j.voteYes}</td>
                      ) : null}
                      {typeof j.voteNeutral === 'number' ? (
                        <td style={tableLayout.voteNeutral}>{j.voteNeutral}</td>
                      ) : null}
                      {typeof j.voteNo === 'number' ? (
                        <td style={tableLayout.voteNo}>{j.voteNo}</td>
                      ) : null}
                      {typeof j.votePercent === 'number' ? (
                        <td style={tableLayout.votePercent}>
                          {j.votePercent === 1 ? 100 : (j.votePercent * 100).toFixed(1)}
                        </td>
                      ) : null}
                      {typeof j.votePercent === 'number' ? (
                        <td style={tableLayout.isPublish}>
                          {j.isPublish ? (
                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                          ) : (
                            <CloseCircleTwoTone twoToneColor="#ef1b1b" />
                          )}
                        </td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <hr />
          </div>
        );
      })}
    </div>
  );
};

export default History;
