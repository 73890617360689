import React, { useState, useEffect } from 'react';
import { Avatar, List, Spin, message } from 'antd';
import { SmileOutlined, MehOutlined, FrownOutlined, NotificationOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { isUndefined } from 'util';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import RevisionProps, { defaultRevisionProps } from '../props/revision';
import irUrl from '../utils/irUrl';
import RevisionVote from '../components/RevisionVote';
import RevisionVoteNew from '../components/RevisionVoteNew';
import { useRecoilValue } from 'recoil';
import { userState } from '../store/atom';
import { postRequest } from '../utils/ajax';

type TableType = 'st' | 'sl' | 'dpst' | 'dp' | 'fr';

interface MatchParams {
  tableid: TableType;
  entryNo: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

const voterIcon: any = {
  yes: {
    avatar: <SmileOutlined />,
    color: '#52c41a',
  },
  neutral: {
    avatar: <MehOutlined />,
    color: '#fce205',
  },
  no: {
    avatar: <FrownOutlined />,
    color: '#cd5c5c',
  },
  notification: {
    avatar: <NotificationOutlined />,
    color: '#b2b2b2',
  },
};

const Song: React.FC<MatchProps> = ({ match }) => {
  const [revisionData, setRevisionData] = useState<RevisionProps>(defaultRevisionProps);
  const [spinning, setSpinning] = useState(false);
  const [table, setTable] = useState<string>(match.params.tableid);

  let spin: JSX.Element | null = null;

  const user = useRecoilValue(userState);
  const { formatMessage } = useIntl();

  useEffect(() => {
    const onTableChange = async () => {
      const result = await postRequest(`/api/revision/${match.params.entryNo}`, {
        table,
      });
      if (!result.success) {
        if (result.error) {
          message.error(formatMessage({ id: `error.${result.error}` }));
        } else message.error('Unknown Error.');
      } else {
        setRevisionData({ ...result.source });
      }
      setSpinning(false);
    };

    setSpinning(true);
    setRevisionData(defaultRevisionProps);
    setTable(match.params.tableid);
    onTableChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.tableid, match.params.entryNo]);

  if (spinning)
    spin = (
      <div className="normal-container" style={{ textAlign: 'center' }}>
        <Spin size="large" spinning={spinning} />
      </div>
    );
  else spin = null;

  return (
    <div>
      {spin}
      {spin || revisionData.entryNo === 0 || revisionData.type === 'fix' ? null : (
        <div className="normal-container">
          <div className="framed text-center">
            <h1>
              {match.params.tableid === 'dpst' ? 'st' : ''}
              {match.params.tableid === 'dp' ? 'sl' : ''}
              {match.params.tableid !== 'dpst' && match.params.tableid !== 'dp' ? match.params.tableid : ''}
              {revisionData.level}
              {!isUndefined(revisionData.changeTo) ? `→${revisionData.changeTo}` : ''}
              &nbsp;{revisionData.title}
            </h1>
            <h2>{revisionData.artist}</h2>
            <table className="normal-table" style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <th style={{ width: '100px' }}>{formatMessage({ id: 'songPage.thSubmit.1' })}</th>
                  <td>
                    {(() => {
                      if (revisionData.type === 'transfer') {
                        if (match.params.tableid === 'st' || match.params.tableid === 'dpst')
                          return formatMessage({
                            id: `songPage.type.${revisionData.type}.1`,
                          });
                        if (match.params.tableid === 'sl' || match.params.tableid === 'dp')
                          return formatMessage({
                            id: `songPage.type.${revisionData.type}.0`,
                          });
                      }
                      return formatMessage({
                        id: `songPage.type.${revisionData.type}`,
                      });
                    })()}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: '100px' }}>Sub</th>
                  <td>
                    <Link to={`../../s/${match.params.tableid}/${revisionData.subNo}`}>Click</Link>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: '100px' }}>LR2IR</th>
                  <td>
                    <a href={irUrl(revisionData.md5, 'lr2')}>Click</a>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: '100px' }}>MinIR</th>
                  <td>
                    <a href={irUrl(revisionData.sha256, 'beatoraja')}>Click</a>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: '100px' }}>{formatMessage({ id: 'songPage.th.2' })}</th>
                  <td>
                    {revisionData.proposer.userlr2id || revisionData.proposer.minirid ? (
                      <Link
                        to={`/user/${match.params.tableid}/${
                          revisionData.proposer.userlr2id || revisionData.proposer.minirid
                        }`}
                      >
                        {revisionData.proposer.nickname}
                      </Link>
                    ) : (
                      revisionData.proposer.nickname
                    )}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: '100px' }}>{formatMessage({ id: 'songPage.th.3' })}</th>
                  <td
                    style={{
                      overflow: 'visible',
                      textOverflow: 'clip',
                      whiteSpace: 'normal',
                    }}
                  >
                    {revisionData.comment}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: '100px' }}>{formatMessage({ id: 'songPage.th.4' })}</th>
                  <td>{dayjs(revisionData.publishedTime).format('YYYY-MM-DD')}</td>
                </tr>
                {revisionData.voteResult !== 'new' ? (
                  <tr>
                    <th style={{ width: '100px' }}>{formatMessage({ id: 'songPage.th.5' })}</th>
                    <td>
                      {revisionData.voteYes}/{revisionData.voteNeutral}/{revisionData.voteNo}(
                      {revisionData.votePercent.toFixed(2)})
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <th style={{ width: '100px' }}>{formatMessage({ id: 'songPage.th.6' })}</th>
                  <td className={`result-${revisionData.voteResult}`}>
                    {formatMessage({
                      id: `songPage.status.${revisionData.voteResult}`,
                    })}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: '100px' }}>{formatMessage({ id: 'songPage.th.7' })}</th>
                  <td>
                    {(() => {
                      let status: string = 'success';
                      if (!user.userid) status = 'notLogin';
                      else if (
                        (table === 'st' &&
                          user.restriction < 8 &&
                          user.restriction < revisionData.level) ||
                        (table === 'sl' &&
                          user.restriction2 < revisionData.level &&
                          user.restriction < 0) ||
                        (table === 'dpst' &&
                          user.restriction3 < 0) ||
                        (
                          table === 'dp' &&
                          user.restriction4 < revisionData.level &&
                          user.restriction3 < 0
                        )
                      )
                        status = 'restricted';
                      else if (revisionData.voteResult !== 'new') {
                        status = 'voteEnd';
                      } else if (revisionData.voteVersion === 1) {
                        const voted = revisionData.voters.find(
                          (i) => i.voter.nickname === user.nickname,
                        );
                        if (voted) {
                          status = voted.voterResult;
                        } else if (
                          dayjs(revisionData.publishedTime).add(30, 'day').isBefore(dayjs())
                        )
                          status = 'timeExceed';
                      } else if (revisionData.voteVersion === 2) {
                        const voted = revisionData.voters.find(
                          (i) => i.voter.nickname === user.nickname,
                        );
                        const voted2 = revisionData.voters2.find(
                          (i) => i.voter.nickname === user.nickname,
                        );
                        if (voted) {
                          status = voted.voterResult;
                        } else if (voted2) {
                          status = voted2.voterResult;
                        } else if (
                          dayjs(revisionData.publishedTime).add(30, 'day').isBefore(dayjs())
                        )
                          status = 'timeExceed';
                      }

                      if (revisionData.voteVersion === 1) {
                        return (
                          <RevisionVote
                            originalLevel={revisionData.level}
                            status={status}
                            submission={revisionData.entryNo}
                            table={table}
                            type={revisionData.type}
                          />
                        );
                      }
                      return (
                        <RevisionVoteNew
                          originalLevel={revisionData.level}
                          status={status}
                          submission={revisionData.entryNo}
                          table={table}
                          type={revisionData.type}
                          changeTo={revisionData.changeTo}
                        />
                      );
                    })()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {revisionData.voteResult !== 'new' || user.userid === 'luxury__a' ? (
            revisionData.voteVersion === 1 ? (
              <List
                bordered
                itemLayout="horizontal"
                dataSource={revisionData.voters}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          size={48}
                          icon={
                            item.voterResult === 'yes' ||
                            item.voterResult === 'neutral' ||
                            item.voterResult === 'no'
                              ? voterIcon[item.voterResult].avatar
                              : voterIcon.notification.avatar
                          }
                          style={
                            item.voterResult === 'yes' ||
                            item.voterResult === 'neutral' ||
                            item.voterResult === 'no'
                              ? {
                                  backgroundColor: voterIcon[item.voterResult].color,
                                }
                              : { backgroundColor: voterIcon.notification.color }
                          }
                        />
                      }
                      title={(() => (
                        <span>
                          {(() => {
                            if (item.voter) {
                              if (item.voter.userlr2id || item.voter.minirid)
                                return (
                                  <Link
                                    to={`/user/${match.params.tableid}/${
                                      item.voter.userlr2id || item.voter.minirid
                                    }`}
                                  >
                                    {item.voter.nickname}
                                  </Link>
                                );
                              if (item.voter.nickname === 'Stella_bot')
                                return formatMessage({
                                  id: 'songPage.notification',
                                });
                              return item.voter.nickname;
                            }
                            return '-Deleted Account-';
                          })()}
                          {!isUndefined(item.voterLevel) ? (
                            <span style={{ marginLeft: '40px', color: '#111e6c' }}>
                              {(() => {
                                if (!isUndefined(item.voterLevel)) {
                                  if (item.voterLevel === 'X') return `${match.params.tableid}?`;
                                  if (
                                    item.voterLevel !== 'sl10-' &&
                                    item.voterLevel !== 'sl12-' &&
                                    item.voterLevel !== 'st0+'
                                  )
                                    return `${match.params.tableid}${item.voterLevel}`;
                                  return item.voterLevel;
                                }
                                return null;
                              })()}
                            </span>
                          ) : null}
                        </span>
                      ))()}
                      description={item.voterComment}
                    />
                  </List.Item>
                )}
              />
            ) : (
              <List
                bordered
                itemLayout="horizontal"
                dataSource={revisionData.voters2}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          size={48}
                          icon={
                            item.voterResult === 'yes' ||
                            item.voterResult === 'neutral' ||
                            item.voterResult === 'no'
                              ? voterIcon[item.voterResult].avatar
                              : voterIcon.notification.avatar
                          }
                          style={
                            item.voterResult === 'yes' ||
                            item.voterResult === 'neutral' ||
                            item.voterResult === 'no'
                              ? {
                                  backgroundColor: voterIcon[item.voterResult].color,
                                }
                              : { backgroundColor: voterIcon.notification.color }
                          }
                        />
                      }
                      title={(() => (
                        <span>
                          {(() => {
                            if (item.voter) {
                              if (item.voter.userlr2id || item.voter.minirid) {
                                return (
                                  <Link
                                    to={`/user/${match.params.tableid}/${
                                      item.voter.userlr2id || item.voter.minirid
                                    }`}
                                  >
                                    {item.voter.nickname}
                                  </Link>
                                );
                              }
                              if (item.voter.nickname === 'Stella_bot')
                                return formatMessage({
                                  id: 'songPage.notification',
                                });
                              return item.voter.nickname;
                            }
                            return '-Deleted Account-';
                          })()}
                        </span>
                      ))()}
                      description={item.voterComment}
                    />
                  </List.Item>
                )}
              />
            )
          ) : (
            <div className="normal-container">
              <div className="framed text-center">
                {formatMessage({ id: 'songPage.lockMessage' })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Song;
