import React, { useState, useEffect } from 'react';
import { Avatar, Button, List, Table, message } from 'antd';
import {
  SmileOutlined,
  MehOutlined,
  FrownOutlined,
  NotificationOutlined,
  EnterOutlined,
  LockOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { isUndefined } from 'util';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import SongProps, { defaultSongProps } from '../../props/song';
import RevisionProps from '../../props/revision';
import irUrl from '../../utils/irUrl';
import Vote from '../../components/Vote';
import Comment from './comment';
import { postRequest } from '../../utils/ajax';
import { useRecoilValue } from 'recoil';
import { userState } from '../../store/atom';
import Spinning from '../../components/Spinning';
import VoteNew from '../../components/VoteNew';

const { Column } = Table;

type TableType = 'st' | 'sl' | 'dpst' | 'dp' | 'fr';

interface MatchParams {
  tableid: TableType;
  entryNo: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

const voterIcon: any = {
  yes: {
    avatar: <SmileOutlined />,
    color: '#52c41a',
  },
  neutral: {
    avatar: <MehOutlined />,
    color: '#fce205',
  },
  no: {
    avatar: <FrownOutlined />,
    color: '#cd5c5c',
  },
  notification: {
    avatar: <NotificationOutlined />,
    color: '#b2b2b2',
  },
};

const Song: React.FC<MatchProps> = ({ match }) => {
  const [songData, setSongData] = useState<SongProps>(defaultSongProps);
  const [songHistory, setSongHistory] = useState<RevisionProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState<string>(match.params.tableid);

  const user = useRecoilValue(userState);
  const { formatMessage } = useIntl();

  useEffect(() => {
    const onTableChange = async () => {
      const result = await postRequest(`/api/song/${match.params.entryNo}`, {
        table,
      });

      const result2 = await postRequest(`/api/revision/search/${match.params.entryNo}`, { table });

      if (!result.success) {
        if (result.error) message.error(formatMessage({ id: `error.${result.error}` }));
        else message.error('Unknown Error.');
      } else if (!result2.success) {
        if (result2.error) message.error(formatMessage({ id: `error.${result2.error}` }));
        else message.error('Unknown Error.');
      } else {
        setSongData({ ...result.source });
        setSongHistory([...result2.source]);
      }
      setLoading(false);
    };

    setLoading(true);
    setSongData(defaultSongProps);
    setTable(match.params.tableid);
    onTableChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.tableid, match.params.entryNo]);

  return loading ? (
    <Spinning />
  ) : (
    <div>
      {songData.entryNo === 0 ? null : (
        <div className="normal-container">
          <div className="framed text-center">
            <h1>
              {match.params.tableid === 'dpst' ? 'st' : ''}
              {match.params.tableid === 'dp' ? 'sl' : ''}
              {match.params.tableid !== 'dpst' && match.params.tableid !== 'dp' ? match.params.tableid : ''}
              {songData.level}&nbsp;
              {songData.title}
            </h1>
            <h2>{songData.artist}</h2>
            <table className="normal-table" style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <th style={{ width: '120px' }}>{formatMessage({ id: 'songPage.th.0' })}</th>
                  <td>
                    <a href={songData.url}>{songData.url}</a>
                  </td>
                </tr>
                <tr>
                  <th>{formatMessage({ id: 'songPage.th.1' })}</th>
                  <td>
                    <a href={songData.url_diff}>{songData.url_diff}</a>
                  </td>
                </tr>
                <tr>
                  <th>LR2IR</th>
                  <td>
                    <a href={irUrl(songData.md5, 'lr2')}>Click</a>
                  </td>
                </tr>
                <tr>
                  <th>MinIR</th>
                  <td>
                    <a href={irUrl(songData.sha256, 'beatoraja')}>Click</a>
                  </td>
                </tr>
                <tr>
                  <th>{formatMessage({ id: 'songPage.th.2' })}</th>
                  <td>
                    {songData.proposer.userlr2id || songData.proposer.minirid ? (
                      <Link
                        to={`/user/${match.params.tableid}/${
                          songData.proposer.userlr2id || songData.proposer.minirid
                        }`}
                      >
                        {songData.proposer.nickname}
                      </Link>
                    ) : (
                      songData.proposer.nickname
                    )}
                  </td>
                </tr>
                <tr>
                  <th>{formatMessage({ id: 'songPage.th.3' })}</th>
                  <td
                    style={{
                      overflow: 'visible',
                      textOverflow: 'clip',
                      whiteSpace: 'normal',
                    }}
                  >
                    {songData.comment}
                  </td>
                </tr>
                <tr>
                  <th>{formatMessage({ id: 'songPage.th.4' })}</th>
                  <td>{dayjs(songData.publishedTime).format('YYYY-MM-DD')}</td>
                </tr>
                {songData.voteResult !== 'new' ? (
                  <tr>
                    <th>{formatMessage({ id: 'songPage.th.5' })}</th>
                    <td>
                      {songData.voteYes}/{songData.voteNeutral}/{songData.voteNo}(
                      {songData.votePercent.toFixed(2)})
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <th>{formatMessage({ id: 'songPage.th.6' })}</th>
                  <td className={`result-${songData.voteResult}`}>
                    {formatMessage({
                      id: `songPage.status.${songData.voteResult}`,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>{formatMessage({ id: 'songPage.th.7' })}</th>
                  <td>
                    {(() => {
                      let status: string = 'success';
                      if (!user.userid) status = 'notLogin';
                      else if (
                        (table === 'st' &&
                          user.restriction < 8 &&
                          user.restriction < songData.level) ||
                        (table === 'sl' &&
                          user.restriction2 < songData.level &&
                          user.restriction < 0) ||
                        (table === 'dpst' && user.restriction3 < 0) ||
                        (table === 'dp' &&
                          user.restriction4 < songData.level &&
                          user.restriction3 < 0)
                      )
                        status = 'restricted';
                      else if (songData.voteResult !== 'new') {
                        status = 'voteEnd';
                      } else if (songData.voteVersion === 1) {
                        const voted = songData.voters.find(
                          (i) => i.voter.nickname === user.nickname,
                        );
                        if (voted) {
                          status = voted.voterResult;
                        } else if (dayjs(songData.publishedTime).add(30, 'day').isBefore(dayjs())) {
                          status = 'timeExceed';
                        }
                      } else if (songData.voteVersion === 2) {
                        const voted = songData.voters.find(
                          (i) => i.voter.nickname === user.nickname,
                        );
                        const voted2 = songData.voters2.find(
                          (i) => i.voter.nickname === user.nickname,
                        );
                        if (voted) {
                          status = voted.voterResult;
                        } else if (voted2) {
                          status = voted2.voterResult;
                        } else if (dayjs(songData.publishedTime).add(30, 'day').isBefore(dayjs())) {
                          status = 'timeExceed';
                        }
                      }

                      if (songData.voteVersion === 1) {
                        return <Vote status={status} submission={songData.entryNo} table={table} />;
                      }
                      return (
                        <VoteNew status={status} submission={songData.entryNo} table={table} />
                      );
                    })()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Table<RevisionProps>
            dataSource={songHistory}
            size="small"
            pagination={false}
            bordered
            rowKey="_id"
            title={() => formatMessage({ id: 'songPage.revisionHistoryNotice' })}
            onHeaderRow={() => {
              return {
                style: { backgroundColor: 'white' },
              };
            }}
          >
            <Column<RevisionProps>
              key="type"
              title={formatMessage({ id: 'songPage.thSubmit.0' })}
              dataIndex="type"
              render={(plaintext, record) => {
                if (record.type === 'transfer') {
                  if (match.params.tableid === 'st' || match.params.tableid === 'dpst')
                    return formatMessage({
                      id: `songPage.type.${record.type}.1`,
                    });
                  if (match.params.tableid === 'sl' || match.params.tableid === 'dp')
                    return formatMessage({
                      id: `songPage.type.${record.type}.0`,
                    });
                }
                return formatMessage({ id: `songPage.type.${record.type}` });
              }}
            />
            <Column<RevisionProps>
              key="level"
              title="Lv"
              dataIndex="level"
              render={(plaintext, record) =>
                isUndefined(record.changeTo) ? record.level : `${record.level}→${record.changeTo}`
              }
            />
            <Column<RevisionProps>
              key="submission"
              title="Sub"
              dataIndex="submission"
              render={(plaintext, record) => (
                <Link to={`../../u/${match.params.tableid}/${record.entryNo}`}>
                  <Button size="small">
                    <EnterOutlined />
                  </Button>
                </Link>
              )}
            />
            <Column<RevisionProps>
              key="vote"
              title={formatMessage({ id: 'songPage.thSubmit.1' })}
              render={(plaintext, record) =>
                record.voteResult === 'new' ? (
                  <span>
                    <LockOutlined />
                    &nbsp;VOTING...
                  </span>
                ) : (
                  `${record.voteYes}/${record.voteNeutral}/${
                    record.voteNo
                  }(${record.votePercent.toFixed(1)})`
                )
              }
            />
            <Column<RevisionProps>
              key="publishedTime"
              title={formatMessage({ id: 'songPage.thSubmit.2' })}
              dataIndex="publishedTime"
              render={(plaintext, record) => dayjs(record.publishedTime).format('YYYY/MM/DD')}
            />
          </Table>
          <div className="normal-container">
            <div className="framed text-center" style={{ width: '100%' }}>
              {!user.userid ? (
                formatMessage({ id: 'votePage.requireLogin' })
              ) : (
                <Comment submission={songData.entryNo} table={table} />
              )}
            </div>
          </div>
          <div className="normal-container">
            <List
              bordered
              itemLayout="horizontal"
              dataSource={songData.comments}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={(() => (
                      <span>
                        <Link
                          to={`/user/${match.params.tableid}/${
                            item.voter?.userlr2id || item.voter?.minirid
                          }`}
                        >
                          {item.voter ? item.voter.nickname : '-Deleted Account-'}
                        </Link>
                      </span>
                    ))()}
                    description={item.voterComment}
                  />
                </List.Item>
              )}
            />
          </div>
          {songData.voteResult !== 'new' || user.userid === 'luxury__a' ? (
            songData.voteVersion === 1 ? (
              <List
                bordered
                itemLayout="horizontal"
                dataSource={songData.voters}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          size={48}
                          icon={
                            item.voterResult === 'yes' ||
                            item.voterResult === 'neutral' ||
                            item.voterResult === 'no'
                              ? voterIcon[item.voterResult].avatar
                              : voterIcon.notification.avatar
                          }
                          style={
                            item.voterResult === 'yes' ||
                            item.voterResult === 'neutral' ||
                            item.voterResult === 'no'
                              ? {
                                  backgroundColor: voterIcon[item.voterResult].color,
                                }
                              : { backgroundColor: voterIcon.notification.color }
                          }
                        />
                      }
                      title={(() => (
                        <span>
                          {(() => {
                            if (item.voter) {
                              if (item.voter.userlr2id || item.voter.minirid)
                                return (
                                  <Link
                                    to={`/user/${match.params.tableid}/${
                                      item.voter.userlr2id || item.voter.minirid
                                    }`}
                                  >
                                    {item.voter.nickname}
                                  </Link>
                                );
                              if (item.voter.nickname === 'Stella_bot')
                                return formatMessage({
                                  id: 'songPage.notification',
                                });
                              return item.voter.nickname;
                            }
                            return '-Deleted Account-';
                          })()}
                          <span
                            style={
                              !isUndefined(item.voterLevel)
                                ? { marginLeft: '40px', color: '#111e6c' }
                                : undefined
                            }
                          >
                            {(() => {
                              if (!isUndefined(item.voterLevel)) {
                                if (item.voterLevel === 'X') return `${match.params.tableid}?`;
                                if (
                                  item.voterLevel !== 'sl10-' &&
                                  item.voterLevel !== 'sl12-' &&
                                  item.voterLevel !== 'st0+'
                                )
                                  return `${match.params.tableid}${item.voterLevel}`;
                                return item.voterLevel;
                              }
                              return null;
                            })()}
                          </span>
                        </span>
                      ))()}
                      description={item.voterComment}
                    />
                  </List.Item>
                )}
              />
            ) : (
              <List
                bordered
                itemLayout="horizontal"
                dataSource={songData.voters2}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          size={48}
                          icon={
                            item.voterResult === 'yes' ||
                            item.voterResult === 'neutral' ||
                            item.voterResult === 'no'
                              ? voterIcon[item.voterResult].avatar
                              : voterIcon.notification.avatar
                          }
                          style={
                            item.voterResult === 'yes' ||
                            item.voterResult === 'neutral' ||
                            item.voterResult === 'no'
                              ? {
                                  backgroundColor: voterIcon[item.voterResult].color,
                                }
                              : { backgroundColor: voterIcon.notification.color }
                          }
                        />
                      }
                      title={(() => (
                        <span>
                          {(() => {
                            if (item.voter) {
                              if (item.voter.userlr2id || item.voter.minirid)
                                return (
                                  <Link
                                    to={`/user/${match.params.tableid}/${
                                      item.voter.userlr2id || item.voter.minirid
                                    }`}
                                  >
                                    {item.voter.nickname}
                                  </Link>
                                );
                              if (item.voter.nickname === 'Stella_bot')
                                return formatMessage({
                                  id: 'songPage.notification',
                                });
                              return item.voter.nickname;
                            }
                            return '-Deleted Account-';
                          })()}
                          {item.voterOption?.difficulty ? (
                            <span style={{ marginLeft: '40px', color: '#111e6c' }}>
                              {formatMessage({
                                id: `songPage.difficulty.${item.voterOption.difficulty}`,
                              })}
                            </span>
                          ) : null}
                        </span>
                      ))()}
                      description={item.voterComment}
                    />
                  </List.Item>
                )}
              />
            )
          ) : (
            <div className="normal-container">
              <div className="framed text-center">
                {formatMessage({ id: 'songPage.lockMessage' })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Song;
