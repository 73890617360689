import { atom } from 'recoil';

export const defaultUserState = {
  success: false,
  userid: '',
  nickname: '',
  dan: 0,
  dan2: 0,
  stairway: [],
  stairway2: [],
  stairway3: [],
  stairway4: [],
  stairway5: [],
  playData: {
    userLevel: 0,
    userSkill: '',
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  playData2: {
    userLevel: 0,
    userSkill: '',
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  playData3: {
    userLevel: 0,
    userSkill: '',
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  playData4: {
    userLevel: 0,
    userSkill: '',
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  playData5: {
    userLevel: 0,
    userSkill: '',
    nickname: '',
    exp: 0,
    pfc: 0,
    fc: 0,
    exhc: 0,
    hc: 0,
    gc: 0,
    ec: 0,
    laec: 0,
    aec: 0,
    fa: 0,
  },
  restriction: -1,
  restriction2: -1,
  restriction3: -1,
  restriction4: -1,
  restriction5: -1,
  playerName: '',
  lastUpdate: new Date(0),
  lastNicknameUpdate: new Date(0),
  ban: false,
  banReason: '',
  userlr2id: 0,
  minirid: '',
  adult: false,
};

export const collapseState = atom({
  key: 'collapse',
  default: false,
});

export const breakpointState = atom({
  key: 'breakpoint',
  default: window.innerWidth < 1200,
});

export const userState = atom({
  key: 'user',
  default: defaultUserState,
});
