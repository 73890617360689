import React, { FC, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, message, Popover, Space, Typography } from 'antd';
import { useRecoilValue } from 'recoil';
import { userState } from '../store/atom';
import { useIntl } from 'react-intl';
import { postRequest } from '../utils/ajax';
import { Link } from 'react-router-dom';
import { QuestionCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Item } = Form;
const { Paragraph } = Typography;

interface SettingsProps {
  callback: Function;
}

const Settings: FC<SettingsProps> = ({ callback }) => {
  const [form] = Form.useForm();
  const user = useRecoilValue(userState);
  const [changing, setChanging] = useState(false);
  const [changing2, setChanging2] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const onFinish = async (values: any) => {
    if (
      !values.nickname ||
      values.nickname.length < 2 ||
      values.nickname.length > 12 ||
      !/^\S{2,12}$/.test(values.nickname)
    ) {
      message.error(formatMessage({ id: `error.ECHANGENICK1` }));
    } else if (values.nickname === user.nickname) {
      message.error(formatMessage({ id: `error.ECHANGENICK2` }));
    } else {
      setChanging(true);
      const result = await postRequest('/api/user/change-nickname', {
        ...values,
      });
      setChanging(false);
      if (!result.success) {
        if (result.error) {
          message.error(formatMessage({ id: `error.${result.error}` }));
        } else message.error('Unknown Error.');
      } else {
        message.success(formatMessage({ id: 'loginInfo.update.1' }));
        callback(values.nickname);
      }
    }
  };

  const onToggleAdult = async (value: boolean) => {
    setChanging2(true);
    const result = await postRequest('/api/user/change-adult', {
      value,
    });
    setChanging2(false);
    if (!result.success) {
      if (result.error) {
        message.error(formatMessage({ id: `error.${result.error}` }));
      } else message.error('Unknown Error.');
    } else {
      setChanging2(false);
      message.success(formatMessage({ id: 'loginInfo.update.1' }));
    }
  };
  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Form form={form} layout="inline" onFinish={onFinish}>
          <Item
            label={formatMessage({ id: 'settings.changeNickname' })}
            name="nickname"
            initialValue={user.nickname}
          >
            <Input placeholder={formatMessage({ id: 'settings.changeNicknamePlaceholder' })} />
          </Item>
          <Button type="primary" htmlType="submit" loading={changing}>
            {formatMessage({ id: 'settings.changeButton' })}
          </Button>
        </Form>
        <Paragraph>
          {dayjs(user.lastNicknameUpdate).add(60, 'day').format('YYYY-MM-DD HH:mm:ss')}
          {formatMessage({ id: 'settings.remainChangeNickname' })}
        </Paragraph>
        <Checkbox
          onChange={(e) => {
            onToggleAdult(e.target.checked);
          }}
          disabled={changing2}
        >
          {formatMessage({ id: 'settings.adultCheck' })}&nbsp;
          <Popover
            content={
              <span>
                {formatMessage({ id: 'settings.adultCheckPopover' })}
                &nbsp;<Link to="/rules">Click</Link>
              </span>
            }
          >
            <span>
              <QuestionCircleFilled />
            </span>
          </Popover>{' '}
        </Checkbox>
      </Space>
    </div>
  );
};

export default Settings;
